import { API, graphqlOperation, Auth } from 'aws-amplify';
import { createUserProfile } from '../graphql/mutations';
import { getUserProfile } from '../graphql/queries';
import { updateUserProfile as updateUserProfileMutation } from '../graphql/mutations';

export const saveUserProfile = async (userId, username, profileData) => {
  try {
    const existingProfile = await fetchUserProfile(userId);

    if (existingProfile) {
      return updateUserProfile(userId, username, profileData);
    } else {
      // If the profile doesn't exist, create it
      return await createUsersProfile(userId, username, profileData);
    }
  } catch (error) {
    // Create a new profile if fetching existing profile fails
    return await createUserProfile(userId, username, profileData);
  }
};

// Assumes createUserProfile function creates a new user profile
const createUsersProfile = async (userId, username, profileData) => {
  const userProfileInput = {
    id: userId,
    username: username,
    preferences: formatProfileData(profileData).preferences,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };

  try {
    await API.graphql(graphqlOperation(createUserProfile, { input: userProfileInput }));
    return userProfileInput;
  } catch (error) {
    throw error;
  }
};

export const formatProfileData = (userSelections) => {
  if (!userSelections || typeof userSelections !== 'object') {
    console.warn('Invalid user selections:', userSelections);
    return { preferences: [] };
  }
  const preferences = [];
  Object.entries(userSelections.options || {}).forEach(([key, value]) => {
    if (value) preferences.push(key);
  });
  Object.entries(userSelections.subcategories || {}).forEach(([key, value]) => {
    if (value) preferences.push(key);
  });
  return { preferences };
};
export const fetchUserProfile = async (userId) => {
  try {
    const response = await API.graphql(graphqlOperation(getUserProfile, { id: userId }));
    const userProfileInput = {
      id: userId,
      username: Auth.currentUserInfo(),
      preferences: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
    if (response.data.getUserProfile === null) {
      const newProfile = await API.graphql(graphqlOperation(createUserProfile, { input: userProfileInput }));
      const response = await API.graphql(graphqlOperation(getUserProfile, { id: userId }));
      return response.data.getUserProfile;
    }
    return response.data.getUserProfile;
  } catch (error) {
    throw error;
  }
};
export const updateUserProfile = async (userId, username, userProfile) => {
  try {
    const userProfileInput = {
      id: userId,
      username: username,
      preferences: userProfile.preferences.preferences,
      updatedAt: new Date().toISOString()
    };
    const response = await API.graphql(graphqlOperation(updateUserProfileMutation, { input: userProfileInput }));
  } catch (error) {
    throw error;
  }
};
