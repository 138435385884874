export const listChatMessageHistoriesbySessionAndUser = /* GraphQL */ `
  query listChatMessageHistoriesbySessionAndUser(
    $filter: ModelChatMessageHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessageHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        content
        sender
        context
        type
        timestamp
        messageID
        session
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
    }
  }
`;
export const listCustomChatModels = /* GraphQL */ `
  query ListCustomChatModels(
    $filter: ModelCustomChatModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomChatModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        name
        purpose
        persona
        model_ids {
          chat
          backend
          image
          tts
          stt
          embedding
          __typename
        }
        custom_instructions {
          chat
          backend
          image
          tts
          stt
          __typename
        }
        dataSources
        integrations
        custom_tools {
          action
          IntegrationID
          description
          whenToUse
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
    }
  }
`;