/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      username
      preferences
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      username
      preferences
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      username
      preferences
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDataSource = /* GraphQL */ `
  mutation CreateDataSource(
    $input: CreateDataSourceInput!
    $condition: ModelDataSourceConditionInput
  ) {
    createDataSource(input: $input, condition: $condition) {
      id
      userId
      name
      topic
      includeSources
      excludeSources
      urls
      files
      customDataRequest
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDataSource = /* GraphQL */ `
  mutation UpdateDataSource(
    $input: UpdateDataSourceInput!
    $condition: ModelDataSourceConditionInput
  ) {
    updateDataSource(input: $input, condition: $condition) {
      id
      userId
      name
      topic
      includeSources
      excludeSources
      urls
      files
      customDataRequest
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDataSource = /* GraphQL */ `
  mutation DeleteDataSource(
    $input: DeleteDataSourceInput!
    $condition: ModelDataSourceConditionInput
  ) {
    deleteDataSource(input: $input, condition: $condition) {
      id
      userId
      name
      topic
      includeSources
      excludeSources
      urls
      files
      customDataRequest
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCustomChatModels = /* GraphQL */ `
  mutation CreateCustomChatModels(
    $input: CreateCustomChatModelsInput!
    $condition: ModelCustomChatModelsConditionInput
  ) {
    createCustomChatModels(input: $input, condition: $condition) {
      id
      userId
      name
      purpose
      persona
      model_ids {
        chat
        backend
        image
        tts
        stt
        embedding
        __typename
      }
      custom_instructions {
        chat
        backend
        image
        tts
        stt
        __typename
      }
      dataSources
      integrations
      custom_tools {
        action
        IntegrationID
        description
        whenToUse
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCustomChatModels = /* GraphQL */ `
  mutation UpdateCustomChatModels(
    $input: UpdateCustomChatModelsInput!
    $condition: ModelCustomChatModelsConditionInput
  ) {
    updateCustomChatModels(input: $input, condition: $condition) {
      id
      userId
      name
      purpose
      persona
      model_ids {
        chat
        backend
        image
        tts
        stt
        embedding
        __typename
      }
      custom_instructions {
        chat
        backend
        image
        tts
        stt
        __typename
      }
      dataSources
      integrations
      custom_tools {
        action
        IntegrationID
        description
        whenToUse
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCustomChatModels = /* GraphQL */ `
  mutation DeleteCustomChatModels(
    $input: DeleteCustomChatModelsInput!
    $condition: ModelCustomChatModelsConditionInput
  ) {
    deleteCustomChatModels(input: $input, condition: $condition) {
      id
      userId
      name
      purpose
      persona
      model_ids {
        chat
        backend
        image
        tts
        stt
        embedding
        __typename
      }
      custom_instructions {
        chat
        backend
        image
        tts
        stt
        __typename
      }
      dataSources
      integrations
      custom_tools {
        action
        IntegrationID
        description
        whenToUse
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatModel = /* GraphQL */ `
  mutation CreateChatModel(
    $input: CreateChatModelInput!
    $condition: ModelChatModelConditionInput
  ) {
    createChatModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatModel = /* GraphQL */ `
  mutation UpdateChatModel(
    $input: UpdateChatModelInput!
    $condition: ModelChatModelConditionInput
  ) {
    updateChatModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatModel = /* GraphQL */ `
  mutation DeleteChatModel(
    $input: DeleteChatModelInput!
    $condition: ModelChatModelConditionInput
  ) {
    deleteChatModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBackendModel = /* GraphQL */ `
  mutation CreateBackendModel(
    $input: CreateBackendModelInput!
    $condition: ModelBackendModelConditionInput
  ) {
    createBackendModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBackendModel = /* GraphQL */ `
  mutation UpdateBackendModel(
    $input: UpdateBackendModelInput!
    $condition: ModelBackendModelConditionInput
  ) {
    updateBackendModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBackendModel = /* GraphQL */ `
  mutation DeleteBackendModel(
    $input: DeleteBackendModelInput!
    $condition: ModelBackendModelConditionInput
  ) {
    deleteBackendModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEmbeddingModel = /* GraphQL */ `
  mutation CreateEmbeddingModel(
    $input: CreateEmbeddingModelInput!
    $condition: ModelEmbeddingModelConditionInput
  ) {
    createEmbeddingModel(input: $input, condition: $condition) {
      id
      model
      provider
      host
      vectorDimensions
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmbeddingModel = /* GraphQL */ `
  mutation UpdateEmbeddingModel(
    $input: UpdateEmbeddingModelInput!
    $condition: ModelEmbeddingModelConditionInput
  ) {
    updateEmbeddingModel(input: $input, condition: $condition) {
      id
      model
      provider
      host
      vectorDimensions
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmbeddingModel = /* GraphQL */ `
  mutation DeleteEmbeddingModel(
    $input: DeleteEmbeddingModelInput!
    $condition: ModelEmbeddingModelConditionInput
  ) {
    deleteEmbeddingModel(input: $input, condition: $condition) {
      id
      model
      provider
      host
      vectorDimensions
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createImageModel = /* GraphQL */ `
  mutation CreateImageModel(
    $input: CreateImageModelInput!
    $condition: ModelImageModelConditionInput
  ) {
    createImageModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateImageModel = /* GraphQL */ `
  mutation UpdateImageModel(
    $input: UpdateImageModelInput!
    $condition: ModelImageModelConditionInput
  ) {
    updateImageModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteImageModel = /* GraphQL */ `
  mutation DeleteImageModel(
    $input: DeleteImageModelInput!
    $condition: ModelImageModelConditionInput
  ) {
    deleteImageModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTtsModel = /* GraphQL */ `
  mutation CreateTtsModel(
    $input: CreateTtsModelInput!
    $condition: ModelTtsModelConditionInput
  ) {
    createTtsModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTtsModel = /* GraphQL */ `
  mutation UpdateTtsModel(
    $input: UpdateTtsModelInput!
    $condition: ModelTtsModelConditionInput
  ) {
    updateTtsModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTtsModel = /* GraphQL */ `
  mutation DeleteTtsModel(
    $input: DeleteTtsModelInput!
    $condition: ModelTtsModelConditionInput
  ) {
    deleteTtsModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSttModel = /* GraphQL */ `
  mutation CreateSttModel(
    $input: CreateSttModelInput!
    $condition: ModelSttModelConditionInput
  ) {
    createSttModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSttModel = /* GraphQL */ `
  mutation UpdateSttModel(
    $input: UpdateSttModelInput!
    $condition: ModelSttModelConditionInput
  ) {
    updateSttModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSttModel = /* GraphQL */ `
  mutation DeleteSttModel(
    $input: DeleteSttModelInput!
    $condition: ModelSttModelConditionInput
  ) {
    deleteSttModel(input: $input, condition: $condition) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAPI = /* GraphQL */ `
  mutation CreateAPI(
    $input: CreateAPIInput!
    $condition: ModelAPIConditionInput
  ) {
    createAPI(input: $input, condition: $condition) {
      id
      description
      category
      openapi_json_url
      openapi_yaml_url
      license
      openapiVer
      link
      updated
      externalDocs
      termsofService
      contact
      title
      clientRegistation
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAPI = /* GraphQL */ `
  mutation UpdateAPI(
    $input: UpdateAPIInput!
    $condition: ModelAPIConditionInput
  ) {
    updateAPI(input: $input, condition: $condition) {
      id
      description
      category
      openapi_json_url
      openapi_yaml_url
      license
      openapiVer
      link
      updated
      externalDocs
      termsofService
      contact
      title
      clientRegistation
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAPI = /* GraphQL */ `
  mutation DeleteAPI(
    $input: DeleteAPIInput!
    $condition: ModelAPIConditionInput
  ) {
    deleteAPI(input: $input, condition: $condition) {
      id
      description
      category
      openapi_json_url
      openapi_yaml_url
      license
      openapiVer
      link
      updated
      externalDocs
      termsofService
      contact
      title
      clientRegistation
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatHistorySession = /* GraphQL */ `
  mutation CreateChatHistorySession(
    $input: CreateChatHistorySessionInput!
    $condition: ModelChatHistorySessionConditionInput
  ) {
    createChatHistorySession(input: $input, condition: $condition) {
      id
      owner
      topic
      session
      timestamp
      messages {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatHistorySession = /* GraphQL */ `
  mutation UpdateChatHistorySession(
    $input: UpdateChatHistorySessionInput!
    $condition: ModelChatHistorySessionConditionInput
  ) {
    updateChatHistorySession(input: $input, condition: $condition) {
      id
      owner
      topic
      session
      timestamp
      messages {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatHistorySession = /* GraphQL */ `
  mutation DeleteChatHistorySession(
    $input: DeleteChatHistorySessionInput!
    $condition: ModelChatHistorySessionConditionInput
  ) {
    deleteChatHistorySession(input: $input, condition: $condition) {
      id
      owner
      topic
      session
      timestamp
      messages {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatHistoryMessage = /* GraphQL */ `
  mutation CreateChatHistoryMessage(
    $input: CreateChatHistoryMessageInput!
    $condition: ModelChatHistoryMessageConditionInput
  ) {
    createChatHistoryMessage(input: $input, condition: $condition) {
      id
      owner
      content
      sender
      context
      type
      backend
      timestamp
      session {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatHistoryMessage = /* GraphQL */ `
  mutation UpdateChatHistoryMessage(
    $input: UpdateChatHistoryMessageInput!
    $condition: ModelChatHistoryMessageConditionInput
  ) {
    updateChatHistoryMessage(input: $input, condition: $condition) {
      id
      owner
      content
      sender
      context
      type
      backend
      timestamp
      session {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatHistoryMessage = /* GraphQL */ `
  mutation DeleteChatHistoryMessage(
    $input: DeleteChatHistoryMessageInput!
    $condition: ModelChatHistoryMessageConditionInput
  ) {
    deleteChatHistoryMessage(input: $input, condition: $condition) {
      id
      owner
      content
      sender
      context
      type
      backend
      timestamp
      session {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      username
      UserProfile {
        id
        username
        preferences
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ChatHistorySessions {
        nextToken
        startedAt
        __typename
      }
      DataSources {
        nextToken
        startedAt
        __typename
      }
      Integrations {
        nextToken
        startedAt
        __typename
      }
      customChatModels {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserProfileId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      username
      UserProfile {
        id
        username
        preferences
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ChatHistorySessions {
        nextToken
        startedAt
        __typename
      }
      DataSources {
        nextToken
        startedAt
        __typename
      }
      Integrations {
        nextToken
        startedAt
        __typename
      }
      customChatModels {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserProfileId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      username
      UserProfile {
        id
        username
        preferences
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ChatHistorySessions {
        nextToken
        startedAt
        __typename
      }
      DataSources {
        nextToken
        startedAt
        __typename
      }
      Integrations {
        nextToken
        startedAt
        __typename
      }
      customChatModels {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserProfileId
      __typename
    }
  }
`;
export const createChatMessageHistory = /* GraphQL */ `
  mutation CreateChatMessageHistory(
    $input: CreateChatMessageHistoryInput!
    $condition: ModelChatMessageHistoryConditionInput
  ) {
    createChatMessageHistory(input: $input, condition: $condition) {
      owner
      content
      sender
      context
      type
      timestamp
      messageID
      session
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatMessageHistory = /* GraphQL */ `
  mutation UpdateChatMessageHistory(
    $input: UpdateChatMessageHistoryInput!
    $condition: ModelChatMessageHistoryConditionInput
  ) {
    updateChatMessageHistory(input: $input, condition: $condition) {
      owner
      content
      sender
      context
      type
      timestamp
      messageID
      session
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatMessageHistory = /* GraphQL */ `
  mutation DeleteChatMessageHistory(
    $input: DeleteChatMessageHistoryInput!
    $condition: ModelChatMessageHistoryConditionInput
  ) {
    deleteChatMessageHistory(input: $input, condition: $condition) {
      owner
      content
      sender
      context
      type
      timestamp
      messageID
      session
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatSessionHistory = /* GraphQL */ `
  mutation CreateChatSessionHistory(
    $input: CreateChatSessionHistoryInput!
    $condition: ModelChatSessionHistoryConditionInput
  ) {
    createChatSessionHistory(input: $input, condition: $condition) {
      id
      owner
      topic
      emoji
      session
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatSessionHistory = /* GraphQL */ `
  mutation UpdateChatSessionHistory(
    $input: UpdateChatSessionHistoryInput!
    $condition: ModelChatSessionHistoryConditionInput
  ) {
    updateChatSessionHistory(input: $input, condition: $condition) {
      id
      owner
      topic
      emoji
      session
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatSessionHistory = /* GraphQL */ `
  mutation DeleteChatSessionHistory(
    $input: DeleteChatSessionHistoryInput!
    $condition: ModelChatSessionHistoryConditionInput
  ) {
    deleteChatSessionHistory(input: $input, condition: $condition) {
      id
      owner
      topic
      emoji
      session
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createModelNotes = /* GraphQL */ `
  mutation CreateModelNotes(
    $input: CreateModelNotesInput!
    $condition: ModelModelNotesConditionInput
  ) {
    createModelNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateModelNotes = /* GraphQL */ `
  mutation UpdateModelNotes(
    $input: UpdateModelNotesInput!
    $condition: ModelModelNotesConditionInput
  ) {
    updateModelNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteModelNotes = /* GraphQL */ `
  mutation DeleteModelNotes(
    $input: DeleteModelNotesInput!
    $condition: ModelModelNotesConditionInput
  ) {
    deleteModelNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNotesModel = /* GraphQL */ `
  mutation CreateNotesModel(
    $input: CreateNotesModelInput!
    $condition: ModelNotesModelConditionInput
  ) {
    createNotesModel(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNotesModel = /* GraphQL */ `
  mutation UpdateNotesModel(
    $input: UpdateNotesModelInput!
    $condition: ModelNotesModelConditionInput
  ) {
    updateNotesModel(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNotesModel = /* GraphQL */ `
  mutation DeleteNotesModel(
    $input: DeleteNotesModelInput!
    $condition: ModelNotesModelConditionInput
  ) {
    deleteNotesModel(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNotes = /* GraphQL */ `
  mutation CreateNotes(
    $input: CreateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    createNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNotes = /* GraphQL */ `
  mutation UpdateNotes(
    $input: UpdateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    updateNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNotes = /* GraphQL */ `
  mutation DeleteNotes(
    $input: DeleteNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    deleteNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createModelContext = /* GraphQL */ `
  mutation CreateModelContext(
    $input: CreateModelContextInput!
    $condition: ModelModelContextConditionInput
  ) {
    createModelContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateModelContext = /* GraphQL */ `
  mutation UpdateModelContext(
    $input: UpdateModelContextInput!
    $condition: ModelModelContextConditionInput
  ) {
    updateModelContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteModelContext = /* GraphQL */ `
  mutation DeleteModelContext(
    $input: DeleteModelContextInput!
    $condition: ModelModelContextConditionInput
  ) {
    deleteModelContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContextModel = /* GraphQL */ `
  mutation CreateContextModel(
    $input: CreateContextModelInput!
    $condition: ModelContextModelConditionInput
  ) {
    createContextModel(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContextModel = /* GraphQL */ `
  mutation UpdateContextModel(
    $input: UpdateContextModelInput!
    $condition: ModelContextModelConditionInput
  ) {
    updateContextModel(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContextModel = /* GraphQL */ `
  mutation DeleteContextModel(
    $input: DeleteContextModelInput!
    $condition: ModelContextModelConditionInput
  ) {
    deleteContextModel(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContext = /* GraphQL */ `
  mutation CreateContext(
    $input: CreateContextInput!
    $condition: ModelContextConditionInput
  ) {
    createContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContext = /* GraphQL */ `
  mutation UpdateContext(
    $input: UpdateContextInput!
    $condition: ModelContextConditionInput
  ) {
    updateContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContext = /* GraphQL */ `
  mutation DeleteContext(
    $input: DeleteContextInput!
    $condition: ModelContextConditionInput
  ) {
    deleteContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createModelInstruct = /* GraphQL */ `
  mutation CreateModelInstruct(
    $input: CreateModelInstructInput!
    $condition: ModelModelInstructConditionInput
  ) {
    createModelInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateModelInstruct = /* GraphQL */ `
  mutation UpdateModelInstruct(
    $input: UpdateModelInstructInput!
    $condition: ModelModelInstructConditionInput
  ) {
    updateModelInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteModelInstruct = /* GraphQL */ `
  mutation DeleteModelInstruct(
    $input: DeleteModelInstructInput!
    $condition: ModelModelInstructConditionInput
  ) {
    deleteModelInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInstructModel = /* GraphQL */ `
  mutation CreateInstructModel(
    $input: CreateInstructModelInput!
    $condition: ModelInstructModelConditionInput
  ) {
    createInstructModel(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInstructModel = /* GraphQL */ `
  mutation UpdateInstructModel(
    $input: UpdateInstructModelInput!
    $condition: ModelInstructModelConditionInput
  ) {
    updateInstructModel(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInstructModel = /* GraphQL */ `
  mutation DeleteInstructModel(
    $input: DeleteInstructModelInput!
    $condition: ModelInstructModelConditionInput
  ) {
    deleteInstructModel(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInstruct = /* GraphQL */ `
  mutation CreateInstruct(
    $input: CreateInstructInput!
    $condition: ModelInstructConditionInput
  ) {
    createInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInstruct = /* GraphQL */ `
  mutation UpdateInstruct(
    $input: UpdateInstructInput!
    $condition: ModelInstructConditionInput
  ) {
    updateInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInstruct = /* GraphQL */ `
  mutation DeleteInstruct(
    $input: DeleteInstructInput!
    $condition: ModelInstructConditionInput
  ) {
    deleteInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCustomTool = /* GraphQL */ `
  mutation CreateCustomTool(
    $input: CreateCustomToolInput!
    $condition: ModelCustomToolConditionInput
  ) {
    createCustomTool(input: $input, condition: $condition) {
      action
      actionInput {
        description
        __typename
      }
      actionInputMapping {
        param
        __typename
      }
      IntegrationID
      description
      whenToUse
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCustomTool = /* GraphQL */ `
  mutation UpdateCustomTool(
    $input: UpdateCustomToolInput!
    $condition: ModelCustomToolConditionInput
  ) {
    updateCustomTool(input: $input, condition: $condition) {
      action
      actionInput {
        description
        __typename
      }
      actionInputMapping {
        param
        __typename
      }
      IntegrationID
      description
      whenToUse
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCustomTool = /* GraphQL */ `
  mutation DeleteCustomTool(
    $input: DeleteCustomToolInput!
    $condition: ModelCustomToolConditionInput
  ) {
    deleteCustomTool(input: $input, condition: $condition) {
      action
      actionInput {
        description
        __typename
      }
      actionInputMapping {
        param
        __typename
      }
      IntegrationID
      description
      whenToUse
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration(
    $input: CreateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    createIntegration(input: $input, condition: $condition) {
      id
      name
      description
      baseUrl
      authType
      authDetails
      headers
      parameters
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIntegration = /* GraphQL */ `
  mutation UpdateIntegration(
    $input: UpdateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    updateIntegration(input: $input, condition: $condition) {
      id
      name
      description
      baseUrl
      authType
      authDetails
      headers
      parameters
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIntegration = /* GraphQL */ `
  mutation DeleteIntegration(
    $input: DeleteIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    deleteIntegration(input: $input, condition: $condition) {
      id
      name
      description
      baseUrl
      authType
      authDetails
      headers
      parameters
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWorkflow = /* GraphQL */ `
  mutation CreateWorkflow(
    $input: CreateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    createWorkflow(input: $input, condition: $condition) {
      id
      name
      outputFormat
      integrations
      inputFormat
      goal
      tone
      forWho
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWorkflow = /* GraphQL */ `
  mutation UpdateWorkflow(
    $input: UpdateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    updateWorkflow(input: $input, condition: $condition) {
      id
      name
      outputFormat
      integrations
      inputFormat
      goal
      tone
      forWho
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWorkflow = /* GraphQL */ `
  mutation DeleteWorkflow(
    $input: DeleteWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    deleteWorkflow(input: $input, condition: $condition) {
      id
      name
      outputFormat
      integrations
      inputFormat
      goal
      tone
      forWho
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDefaultWorkflow = /* GraphQL */ `
  mutation CreateDefaultWorkflow(
    $input: CreateDefaultWorkflowInput!
    $condition: ModelDefaultWorkflowConditionInput
  ) {
    createDefaultWorkflow(input: $input, condition: $condition) {
      id
      name
      outputFormat
      integrations
      inputFormat
      goal
      tone
      forWho
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDefaultWorkflow = /* GraphQL */ `
  mutation UpdateDefaultWorkflow(
    $input: UpdateDefaultWorkflowInput!
    $condition: ModelDefaultWorkflowConditionInput
  ) {
    updateDefaultWorkflow(input: $input, condition: $condition) {
      id
      name
      outputFormat
      integrations
      inputFormat
      goal
      tone
      forWho
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDefaultWorkflow = /* GraphQL */ `
  mutation DeleteDefaultWorkflow(
    $input: DeleteDefaultWorkflowInput!
    $condition: ModelDefaultWorkflowConditionInput
  ) {
    deleteDefaultWorkflow(input: $input, condition: $condition) {
      id
      name
      outputFormat
      integrations
      inputFormat
      goal
      tone
      forWho
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const invokeChatModel = /* GraphQL */ `
  mutation InvokeChatModel($input: InvokeChatModelInput!) {
    invokeChatModel(input: $input) {
      id
      message
      messageID
      __typename
    }
  }
`;
export const publishToSubscription = /* GraphQL */ `
  mutation PublishToSubscription($input: PublishToSubscriptionInput!) {
    publishToSubscription(input: $input) {
      id
      session
      userId
      message
      messageID
      __typename
    }
  }
`;
export const CreateDataSources = /* GraphQL */ `
  mutation CreateDataSources($input: DataSourcesInput!) {
    CreateDataSources(input: $input) {
      response
      __typename
    }
  }
`;
export const CreateTopicForChatHistory = /* GraphQL */ `
  mutation CreateTopicForChatHistory($input: CreateTopicForChatHistoryInput!) {
    CreateTopicForChatHistory(input: $input) {
      id
      session
      topic
      emoji
      user
      __typename
    }
  }
`;
