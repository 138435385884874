import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiHomeOutline, mdiTools, mdiDatabase, mdiLinkVariant, mdiNewspaperVariant, mdiAccount, mdiAccountSettings, mdiLogout, mdiChat, mdiEmoticonDevil,  mdiCodeBlockTags, mdiViewModule, mdiAbTesting, mdiAlphaABox, mdiAccountDetails, mdiSourceRepositoryMultiple, mdiAbugidaDevanagari, mdiApi, mdiRabbitVariant, mdiToolbox } from '@mdi/js';
import logo from '../logo.svg';
import '../styles/SidePanel.css';
import { Auth } from 'aws-amplify';
import { FourGPlusMobiledataOutlined, ModelTraining } from '@mui/icons-material';

const SidePanel = () => {
    const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
    const [user, setUser] = useState(null); // State to store user information
    const profileMenuRef = useRef();

    const toggleProfileMenu = () => setProfileMenuOpen(!isProfileMenuOpen);

    const handleProfileClick = (e) => {
        e.stopPropagation(); 
    };

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            setUser(null); // Clear user information on sign out
        } catch (error) {
            console.error('error signing out: ', error);
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userInfo = await Auth.currentAuthenticatedUser();
                setUser(userInfo);
            } catch (error) {
                console.error('error fetching user: ', error);
            }
        };

        fetchUser();

        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                setProfileMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileMenuRef]);

    return (
        <div className="side-panel">
            <div className="side-panel-header">
                <img src={logo} alt="Logo" className="side-panel-logo" />
                <h2 className="side-panel-title">gavAI</h2>
            </div>
            <nav>
                <ul className="side-panel-nav">
                    <li><NavLink to="/"><Icon path={mdiHomeOutline} size={1} /> Home</NavLink></li>
                    <li><NavLink to="/models"><Icon path={mdiRabbitVariant } size={1} /> Custom Models</NavLink></li>
                    <li><NavLink to="/data-sources"><Icon path={mdiDatabase} size={1} /> Data Sources</NavLink></li>
                    <li><NavLink to="/integrations"><Icon path={mdiLinkVariant} size={1} /> Integrations</NavLink></li>
                    <li><NavLink to="/repos"><Icon path={mdiSourceRepositoryMultiple} size={1} /> Repositories</NavLink></li>
                    <li><NavLink to="/chat"><Icon path={mdiChat} size={1} /> Chat</NavLink></li>
                    <li><NavLink to="/workflows"><Icon path={mdiCodeBlockTags} size={1} /> Workflows</NavLink></li>
                    <li><NavLink to="/dev-tools"><Icon path={mdiToolbox} size={1} /> Dev Tools</NavLink></li>
                </ul>
            </nav>
            <div className="side-panel-profile">
                <div className="profile-info" onClick={toggleProfileMenu}>
                    <div className="profile-picture">
                        {/* Placeholder or actual user image */}
                    </div>
                    <span className="profile-name">{user ? user.username : 'User'}</span>
                </div>
                {isProfileMenuOpen && (
                    <div className="profile-menu" onClick={handleProfileClick}>
                        <ul>
                            <li><NavLink to="/profile"><Icon path={mdiAccount} size={1} /> Customize Profile</NavLink></li>
                            <li><NavLink to="/settings"><Icon path={mdiAccountSettings} size={1} /> Settings</NavLink></li>
                            <li><button onClick={handleSignOut} className="sign-out-button"><Icon path={mdiLogout} size={1} /> Sign Out</button></li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SidePanel;
