import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listWorkflows, listDefaultWorkflows } from '../graphql/queries';
import WorkflowForm from '../components/WorkflowForm';
import ContentContainer from '../containers/ContentContainer';
import Modal from '../components/Modal';
import IgCaption from '../components/IgCaption';

const WorkFlows = () => {
    const [showIgCaptionModal, setShowIgCaptionModal] = useState(false);
    const [workflows, setWorkflows] = useState([]);
    const [userId, setUserId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const igModalRef = useRef(); // Reference for the IG Caption modal
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const openIgModal = () => setShowIgCaptionModal(true);
    const closeIgModal = () => setShowIgCaptionModal(false);
    const [defaultWorkflows, setDefaultWorkflows] = useState([]);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const currentUser = await Auth.currentUserInfo();
                const user = currentUser.id;
                setUserId(user);
                const defaultWorkflowData = await API.graphql(graphqlOperation(listDefaultWorkflows));
                setDefaultWorkflows(defaultWorkflowData.data.listDefaultWorkflows.items);
            } catch (error) {
                console.error("Error fetching workflows:", error);
            }
        };
        fetchWorkflows();
    }, []);

    return (
        <div>
            <ContentContainer>
                <button onClick={openModal}>Create Workflow</button>
                {defaultWorkflows.map((workflow) => (
                    <div key={workflow.id}>
                        {workflow.name}
                        {workflow.id === "ig-caption-create" && (
                            <button onClick={openIgModal}>Open IG Caption Creator</button>
                        )}
                    </div>
                ))}
            </ContentContainer>
            {showIgCaptionModal && (
                <Modal onClose={closeIgModal} modalRef={igModalRef}>
                    <IgCaption userId={userId} />
                </Modal>
            )}

        </div>
    );
};

export default WorkFlows;
