import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import DOMPurify from 'dompurify'; // Import DOMPurify
import '../styles/IgCaption.css';
import '../styles/ChatMessages.css';
import MarkdownToJsx from 'markdown-to-jsx';
import hljs from 'highlight.js';
import { languageIcons } from '../data/languageIcons';
import { mdiContentCopy } from '@mdi/js';
import { Icon } from '@mdi/react';

const IgCaption = ({ userId }) => {
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [responseContent, setResponseContent] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
            setFileUrl(reader.result);
        };
        reader.readAsDataURL(selectedFile);
    };

    const handleUserMessageChange = (e) => {
        setUserMessage(e.target.value);
    };

    const uploadFileToS3 = async () => {
        if (file) {
            const filename = `ig-captions/${Date.now()}-${file.name}`;
            await Storage.put(filename, file, {
                level: 'private',
                contentType: file.type,
            });
            return filename;
        }
        return null;
    };

    useEffect(() => {

        document.querySelectorAll('pre code').forEach((block) => {
            hljs.highlightBlock(block);
        });
    }, []); // Re-run when messages change

    // Function to handle copying code to clipboard
    const copyToClipboard = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            console.log("Copied to clipboard successfully!");
        }, (err) => {
            console.error('Error copying to clipboard: ', err);
        });
    };

    useEffect(() => {

        // Apply highlighting after rendering
        document.querySelectorAll('pre code').forEach((block) => {
            if (block.dataset.highlighted) {
                delete block.dataset.highlighted;
            }
            hljs.highlightElement(block);
        });
    }, [responseContent]);

    const formatCodeBlock = (codeContent, language) => {
        const languageIcon = languageIcons[language] || 'mdiCodeBraces';
        const formattedCodeContent = '\n' + codeContent;
        return (
            <div className="code-container">
                <div className="code-ribbon">
                    <Icon path={languageIcon} size={1} className="language-icon" />
                    <button className="copy-button" onClick={() => copyToClipboard(formattedCodeContent)}>
                        <Icon path={mdiContentCopy} size={0.7} />
                    </button>
                </div>
                <pre><code className={`language-${language}`}>{formattedCodeContent}</code></pre>
            </div>
        );
    };

    const formatMessageStream = (content) => {
        const parts = [];
        const splitContent = content.split(/(```\w+\n[\s\S]*?\n```|<code>[\s\S]*?<\/code>)/);
    
        splitContent.forEach(part => {
            const codeBlockMatch = part.match(/```(\w+)\n([\s\S]*?)\n```/);
            const inlineCodeMatch = part.match(/<code>([\s\S]*?)<\/code>/);
    
            if (codeBlockMatch) {
                const language = codeBlockMatch[1];
                const codeContent = codeBlockMatch[2];
                parts.push(formatCodeBlock(codeContent, language));
            } else if (inlineCodeMatch) {
                const codeContent = inlineCodeMatch[1];
                parts.push(formatInlineCode(codeContent));
            } else {
                // Treat as plain text or Markdown
                parts.push(<MarkdownToJsx>{part}</MarkdownToJsx>);
            }
        });
        return parts;
    };
    
    const formatInlineCode = (codeContent) => {
        return (
            <div className="inline-code-container">
                <code>{codeContent}</code>
                <button className="copy-button" onClick={() => copyToClipboard(codeContent)}>
                    <Icon path={mdiContentCopy} size={0.7} />
                </button>
            </div>
        );
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const uploadedFilename = await uploadFileToS3();
        if (uploadedFilename) {
            const workflowData = {
                files: [uploadedFilename],
                user_message: userMessage,
                userId: userId,
            };

            try {
                const response = await fetch('https://5rctiyobx4lifn6xqwfz4dtozy0tnesw.lambda-url.us-east-1.on.aws/workflow', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(workflowData),
                });

 
                if (response.ok) {
                    const reader = response.body.getReader();
                    let decoder = new TextDecoder();
                    let completeChunk = '';
                    let lastLineProcessed = 0;
    
                    reader.read().then(function processText({ done, value }) {
                        if (done) {
                            setLoading(false);
                            console.log("Stream complete");
                            return;
                        }
                        completeChunk += decoder.decode(value, { stream: true });
    
                        const lines = completeChunk.split('\n');
                        let newContent = ''; // Temporary variable to collect new content
    
                        for (let i = lastLineProcessed; i < lines.length; i++) {
                            try {
                                if (lines[i]) {
                                    const parsedLine = JSON.parse(lines[i]);
                                    newContent += parsedLine.content; // Append new content
                                    lastLineProcessed = i + 1; // Update the last processed line index
                                }
                            } catch (parseError) {
                                console.error('Error parsing line:', parseError);
                            }
                        }
    
                        setResponseContent(prevContent => prevContent + newContent); // Update state once per chunk
    
                        reader.read().then(processText);
                    });
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error('Error sending data to API:', error);
                setLoading(false);
            }
        }
    };

    return (
        <div className="ig-caption-container">
            <form onSubmit={handleSubmit} className="ig-caption-form">
                <input type="file" onChange={handleFileChange} />
                <input
                    type="text"
                    value={userMessage}
                    onChange={handleUserMessageChange}
                    placeholder="Enter your message"
                />
                <button type="submit" disabled={!file || loading}>
                    {loading ? 'Processing...' : 'Create Caption'}
                </button>
            </form>

            {fileUrl && <img src={fileUrl} alt="Uploaded" className="uploaded-image" />}
            {responseContent && (
                <div className="response-container">
                    <h3>Response:</h3>
                    <div>{formatMessageStream(responseContent)}</div>
                </div>
            )}
        </div>
    );
};

export default IgCaption;
