import React, { useEffect } from 'react';

const IntelligentThreatProtection = ({ children }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://b7405edef359.edge.sdk.awswaf.com/b7405edef359/44fa7dfc8bae/challenge.js";
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return <>{children}</>;
};

export default IntelligentThreatProtection;
