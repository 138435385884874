import React, { useEffect } from 'react';
import '../styles/Modal.css';

const Modal = ({ children, onClose, modalRef }) => {
    // Adjust the height of a textarea to fit its content
    const adjustTextareaHeight = (e) => {
        e.target.style.height = 'auto'; // Reset height
        e.target.style.height = e.target.scrollHeight + 'px'; // Set new height
    };

    // Function to close the modal if clicked outside
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        // Attach event listener for clicks outside the modal
        document.addEventListener('mousedown', handleClickOutside);

        // Find and adjust all textareas for their current content
        const textareas = modalRef.current.querySelectorAll('textarea');
        textareas.forEach(textarea => adjustTextareaHeight({target: textarea}));

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <div className="modal-overlay">
            <div className="modal-container" ref={modalRef}>
                {React.Children.map(children, child => {
                    // Clone element to add props if it's a textarea
                    if (child.type === 'textarea') {
                        return React.cloneElement(child, {
                            onChange: (e) => {
                                child.props.onChange && child.props.onChange(e);
                                adjustTextareaHeight(e);
                            }
                        });
                    }
                    return child;
                })}
            </div>
        </div>
    );
};

export default Modal;
