/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fq0z37cuad.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5dvvr2g64nebzfwp7saulu6una.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:670ef2b7-81e0-43ee-baa4-f81899868e1d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xmZxJcL73",
    "aws_user_pools_web_client_id": "1400uru97s9u4gpneqbs4kncoe",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "UserProfiles",
            "region": "us-east-1"
        },
        {
            "tableName": "api-list",
            "region": "us-east-1"
        },
        {
            "tableName": "waitlist",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "gavaiusers3133424-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
