
import React, { useState, useEffect } from 'react';


const GithubRepos = ({ username }) => {
    const [repos, setRepos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://api.github.com/users/${username}/repos`);
                setRepos(response.data);
            } catch (error) {
                console.error('Oops, something went wrong fetching the repos:', error);
            }
        };

        fetchData();
    }, [username]);

    return (
        <div>
            <h2>{username}'s GitHub Repositories</h2>
            <ul>
                {repos.map(repo => (
                    <li key={repo.id}>
                        <a href={repo.html_url} target="_blank" rel="noopener noreferrer">
                            {repo.name}
                        </a> - {repo.description}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GithubRepos;