import React from 'react';
import '../styles/ContentContainer.css';

const ContentContainer = ({ children }) => {
  const containerClass = 'content-container animated-fadeIn';

  return (
    <div className={containerClass}>
      {children}
    </div>
  );
};

export default ContentContainer;
