// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import SidePanel from './components/SidePanel'; 

import Dashboard from './pages/Dashboard';
import Chat from './pages/Chat';
import DataSources from './pages/DataSources'; 
import CustomizeProfile from './pages/CustomizeProfile';
import ChatModel from './pages/ChatModel';
import Integrations from './pages/Integrations';
import Repos from './pages/Repos';
import IntelligentThreatProtection from './components/IntelligentThreatProtection';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';



import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
import WorkFlows from './pages/WorkFlows';

Amplify.configure(awsmobile);

function App({ user }) {
  return (
    <IntelligentThreatProtection>
      <>
        <Router>
          <div className="app-layout"> {/* Add a wrapper for the layout */}
            <SidePanel user={user} /> {/* Include SidePanel */}
              <div className="content-area"> {/* Container for the main content */}
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/profile" element={<CustomizeProfile />} /> 
                  <Route path="/data-sources" element={<DataSources />} />
                  <Route path="/models" element={<ChatModel />} />
                  <Route path="/integrations" element={<Integrations />} />
                  <Route path="/chat" element={<Chat />} />
                  <Route path="/repos" element={<Repos />} />
                  <Route path="/workflows" element={<WorkFlows />} />
                  {/* Other routes */}
                </Routes>
              </div>
          </div>
        </Router>
      </>
    </IntelligentThreatProtection>
  );
};

export default withAuthenticator(App);
