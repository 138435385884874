import React from 'react';
import PrivateUserStorage from '../utils/PrivateUserStorage';

const FolderUpload = (dataSourceName) => {

    const uploadDataSource = async (files) => {
        Promise.all(files.map(file => {
            // Construct a key with the data source name and file name
            const key = `${dataSourceName}/UserUploaded/${file.name}`;
            return PrivateUserStorage.uploadFile(file, key);
        }))
        .then(results => {
            console.log('All files uploaded:', results);
        })
        .catch(error => {
            console.error('Error uploading files:', error);
        });
    };

    const handleFolderUpload = async (event) => {
        const files = Array.from(event.target.files);
        await uploadDataSource(files);
    };

    return (
        <div>
            <input 
                type="file" 
                webkitdirectory="" 
                directory="" 
                onChange={handleFolderUpload} 
            />
            <p>Select a folder to upload</p>
        </div>
    );
};

export default FolderUpload;
