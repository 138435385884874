import React, { useState, useRef, useEffect } from 'react';
import '../styles/Integrations.css';
import Modal from '../components/Modal';
import * as queries from '../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import OpenAPIForm from '../components/OpenAPIForm'; // Import the OpenAPIForm component
import SwaggerForm from '../components/SwaggerForm';
const Integrations = () => {
   
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef();
    const [apiDetails, setApiDetails] = useState({
        name: '', 
        description: '', 
        baseUrl: '', 
        method: 'GET', 
        authType: 'NONE', 
        authDetails: {}, 
        headers: {}, 
        parameters: {} 
    });
    const [integrations, setIntegrations] = useState([]); // Array to store integrations
    const [apiSearchQuery, setApiSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showApiDetailsModal, setShowApiDetailsModal] = useState(false);
    const [selectedApiDetails, setSelectedApiDetails] = useState({});
    const [openApiSchema, setOpenApiSchema] = useState(null);
    const [SwaggerSchema, setSwaggerSchema] = useState({});
    const [isLoading, setIsLoading] = useState(false); // New state for loading status
    // Function to parse api_id and return the part after ':'
    const getServiceIdentifier = (apiId) => {
        const parts = apiId.split(':');
        return parts.length > 1 ? parts[1] : '';
    };
    const handleApiSearch = async () => {
        if (!apiSearchQuery.trim()) {
            setSearchResults([]);
            return;
        }
        setIsLoading(true); // Start loading
    
        try {
            // Create a filter that searches across multiple string fields
            const searchFilter = {
                or: [
                    { title: { wildcard: `*${apiSearchQuery}*` } },
                    { provider: { wildcard: `*${apiSearchQuery}*` } },
                    { description: { wildcard: `*${apiSearchQuery}*` } },
                    // Add more fields as needed
                ]
            };
    
            const searchInput = {
                filter: searchFilter,
                limit: 10 // or any other limit you prefer
            };
    
            const response = await API.graphql(graphqlOperation(queries.searchAPIS, searchInput));
            console.log("Response: ", response);
    
            const searchResults = response.data.searchAPIS.items.map(api => ({
                api_id: api.id,
                title: api.title,
                provider: api.provider,
                openapi_json_url: api.openapi_json_url


            }));
    
            setSearchResults(searchResults);
    
        } catch (error) {
            console.error("Error searching for APIs:", error);
        } finally {
            setIsLoading(false); // End loading
        }
    };
    
    const onSearchButtonClick = () => {
        handleApiSearch();
    };

    const handleApiSearchQueryChange = (e) => {
        setApiSearchQuery(e.target.value);
    };

    const handleApiDetailsChange = (e) => {
        if (['headers', 'parameters', 'authDetails'].includes(e.target.name)) {
            setApiDetails({
                ...apiDetails,
                [e.target.name]: {
                    ...apiDetails[e.target.name],
                    [e.target.dataset.key]: e.target.value
                }
            });
        } else {
            setApiDetails({ ...apiDetails, [e.target.name]: e.target.value });
        }
    };

    const addHeaderField = () => {
        setApiDetails({
            ...apiDetails,
            headers: { ...apiDetails.headers, '': '' }
        });
    };

    const addParameterField = () => {
        setApiDetails({
            ...apiDetails,
            parameters: { ...apiDetails.parameters, '': '' }
        });
    };

    const selectSuggestion = async (suggestion) => {
        try {
            const openapiUrl = suggestion.openapi_json_url;
            // Fetch the OpenAPI schema from the URL
            const schemaResponse = await fetch(openapiUrl);
            
            const openApiSchema = await schemaResponse.json();

            handleOpenApiSchemaUpload(openApiSchema, openapiUrl); // Pass the fetched schema

            // Clear suggestions and update states

            // Close the existing modal before opening the ApiDetailsModal
            setIsModalOpen(false);
            setShowApiDetailsModal(true); // Open the details modal above everything
        } catch (error) {
            console.error("Error fetching API details or OpenAPI schema:", error);
        }
    };

    const handleOpenApiFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const schema = JSON.parse(event.target.result);
                // Determine the type of schema and set it accordingly
                if (schema.swagger) {
                    // Swagger 2.0
                    setSelectedApiDetails({ schema: schema, schemaType: 'swagger' });
                } else if (schema.openapi) {
                    // OpenAPI 3.0
                    setSelectedApiDetails({ schema: schema, schemaType: 'openapi' });
                }
            } catch (error) {
                console.error("Error parsing OpenAPI schema:", error);
            }
        };
        reader.readAsText(file);
    };
    const [apiType, setApiType] = useState({})

    const handleOpenApiSchemaUpload = async (schema, url) => {
        console.log("schema: ", schema);
        try {
            if (schema.swagger) {
                setOpenApiSchema({});
                setSwaggerSchema(schema);
                setApiType({ schemaType: "swagger" });
                console.log(`Set Swagger schema for ${schema.host}${schema.basePath}: `, schema); // Log schema directly
            } else if (schema.openapi) {
                setSwaggerSchema({});
                const schemaInput = {
                    openapi_json: url
                };
                const openApiResponse = await API.graphql(graphqlOperation(queries.generateOpenAPIUI, { input: schemaInput }));
                const responseObj = JSON.parse(openApiResponse.data.generateOpenAPIUI.response)
                console.log("OpenAPI GraphQL Response: ", responseObj)
                setOpenApiSchema(responseObj);
                setApiType({ schemaType: "openapi" });
            }
        } catch (error) {
            console.error("Error parsing OpenAPI schema:", error);
        }
    };
    

    // Function to handle client registration redirection
    const handleClientRegistration = () => {
        if (apiDetails.clientRegistration) {
            window.open(apiDetails.clientRegistration, '_blank');
        }
    };

    const testApiIntegration = async () => {
        try {
            const response = await fetch(apiDetails.baseUrl, {
                method: apiDetails.method,
                headers: new Headers(apiDetails.headers)
            });
            if (response.ok) {
                console.log("API Integration Successful!");
            } else {
                console.error("API Integration Failed: ", response.statusText);
            }
        } catch (error) {
            console.error("Error testing API integration:", error);
        }
    };

    const handleIntegrationSave = () => {
        setIntegrations([...integrations, apiDetails]);
        setIsModalOpen(false);
    };

    const handleApiDetailsClose = () => {
        setApiType({})
        setSwaggerSchema({});
        setOpenApiSchema({});
        setShowApiDetailsModal(false);
        setIsModalOpen(true);
    }
    
    const saveIntegrationsState = () => {
        const currentState = {
            // Include all states that determine the visibility and state of OpenAPIForm
            isModalOpen,
            selectedApiDetails,
            apiType,
            apiSearchQuery,
            openApiSchema,
            SwaggerSchema,
            showApiDetailsModal,
            searchResults
        };
        sessionStorage.setItem('integrationsState', JSON.stringify(currentState));
    };
    

    useEffect(() => {
        const savedState = sessionStorage.getItem('integrationsState');
        if (savedState) {
            const restoredState = JSON.parse(savedState);
            setIsModalOpen(restoredState.isModalOpen);
            setSelectedApiDetails(restoredState.selectedApiDetails);
            setApiSearchQuery(restoredState.apiSearchQuery);
            setSearchResults(restoredState.searchResults);
            setOpenApiSchema(restoredState.openApiSchema);
            console.log("OpenAPI Schema: ", openApiSchema)
            setSwaggerSchema(restoredState.SwaggerSchema);
            setApiType(restoredState.apiType);
            setShowApiDetailsModal(restoredState.showApiDetailsModal);
            
            // ... restore other states
            sessionStorage.removeItem('integrationsState');
        }
    }, []);
    
    return (
        <div className="integrations-container">
            <div className="integrations-header">
                <h2>API Integrations</h2>
            </div>

            <div className="integrations-grid">
                {integrations.map((integration, index) => (
                    <div key={index} className="integration-item">
                        <p>{integration.name}</p>
                    </div>
                ))}
            </div>
            <button className='integrations-button' onClick={() => setIsModalOpen(true)}>Create New Integration</button>
            {isModalOpen && (
                <Modal onClose={() => setIsModalOpen(false)} modalRef={modalRef}>
                    <div className="integrations-search">
                        <div className="search-bar">
                            <input 
                                type="text"
                                value={apiSearchQuery}
                                onChange={handleApiSearchQueryChange}
                                placeholder="Search for APIs"
                            />
                            <button onClick={onSearchButtonClick} disabled={isLoading}>
                                {isLoading ? <div className="loader"></div> : "Search"}
                            </button>
                        </div>
                        {searchResults.length > 0 && (
                            <div className="search-results">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Provider</th>
                                            <th>Service</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchResults.map((result, index) => (
                                            <tr key={index} onClick={() => selectSuggestion(result)}>
                                                <td>{result.title}</td>
                                                <td>{result.provider}</td>
                                                <td>{getServiceIdentifier(result.api_id)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    {apiDetails.clientRegistration && (
                        <button onClick={handleClientRegistration}>Register for API</button>
                    )}
                    </div>

                    <div className="api-form">
                        {Object.keys(apiDetails).map((key) => (
                            <div key={key} className="form-field">
                                <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                                <input 
                                    id={key}
                                    name={key}
                                    type="text" 
                                    value={apiDetails[key] || ''} 
                                    onChange={handleApiDetailsChange}
                                />
                            </div>
                        ))}
                    <div className="form-field">
                            <label htmlFor="method">Method:</label>
                            <select 
                                id="method"
                                name="method"
                                value={apiDetails.method} 
                                onChange={handleApiDetailsChange}
                            >
                                <option value="GET">GET</option>
                                <option value="POST">POST</option>
                                <option value="PUT">PUT</option>
                                <option value="DELETE">DELETE</option>
                                <option value="PATCH">PATCH</option>
                            </select>
                        </div>

                        <div className="headers-parameters">
                            <div className="form-field">
                                <label>Headers:</label>
                                {Object.entries(apiDetails.headers).map(([key, value], index) => (
                                    <div key={index} className="key-value-pair">
                                        <input 
                                            type="text" 
                                            data-key={key} 
                                            value={key} 
                                            placeholder="Header Name"
                                            onChange={(e) => handleApiDetailsChange(e, 'headerKey', index)}
                                        />
                                        <input 
                                            type="text" 
                                            data-key={key} 
                                            value={value} 
                                            placeholder="Header Value"
                                            onChange={(e) => handleApiDetailsChange(e, 'headerValue', index)}
                                        />
                                    </div>
                                ))}
                                <button onClick={addHeaderField}>Add Header</button>
                            </div>

                            <div className="form-field">
                                <label>Parameters:</label>
                                {Object.entries(apiDetails.parameters).map(([key, value], index) => (
                                    <div key={index} className="key-value-pair">
                                        <input 
                                            type="text" 
                                            data-key={key} 
                                            value={key} 
                                            placeholder="Parameter Name"
                                            onChange={(e) => handleApiDetailsChange(e, 'parameterKey', index)}
                                        />
                                        <input 
                                            type="text" 
                                            data-key={key} 
                                            value={value} 
                                            placeholder="Parameter Value"
                                            onChange={(e) => handleApiDetailsChange(e, 'parameterValue', index)}
                                        />
                                    </div>
                                ))}
                                <button onClick={addParameterField}>Add Parameter</button>
                            </div>
                        </div>

                        <button onClick={testApiIntegration}>Test Integration</button>
                    </div>
                    <div className="api-schema-upload">
                        <label htmlFor="openApiSchema">OpenAPI Schema:</label>
                        <input 
                            id="openApiSchema"
                            type="file" 
                            onChange={(e) => handleOpenApiFileUpload(e.target.files[0])}
                        />
                        <button onClick={handleIntegrationSave}>Save Integration</button>
                    </div>
                </Modal>
            )}
            {showApiDetailsModal && (
                <Modal onClose={() => setShowApiDetailsModal(false)} modalRef={modalRef}>
                    <div className="api-details-modal">
                        <button onClick={() => handleApiDetailsClose()}>Close</button>
                        <h2>{selectedApiDetails.title}</h2>
                            <p>{selectedApiDetails.description}</p>
                            {apiType.schemaType === 'swagger' && (
                                <SwaggerForm modalRef={modalRef} jsonSchema={SwaggerSchema} />
                            )}
                            {apiType.schemaType === 'openapi' && (
                                <OpenAPIForm jsonSchema={openApiSchema} onSaveIntegrationsState={saveIntegrationsState} />
                            )}
                        </div>
                    </Modal>
                )}
        </div>
    );
};

export default Integrations;