import React, { useEffect } from 'react';
import { Icon } from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import '../styles/SectionContainer.css';

const SectionContainer = ({ title, data, currentSelections, onAddCustomOption, onNext, onBack, isLastSection, onUpdateSelections }) => {
    useEffect(() => {
    }, [currentSelections]);

    const handleOptionSelect = (option) => {
        const updatedOptions = { ...currentSelections.options, [option]: !currentSelections.options[option] };
        onUpdateSelections(updatedOptions, currentSelections.subcategories);
    };
    
    const handleSubcategorySelect = (option, subcategory) => {
        const subcategoryKey = `${option}-${subcategory}`;
        const updatedSubcategories = { ...currentSelections.subcategories, [subcategoryKey]: !currentSelections.subcategories[subcategoryKey] };
        onUpdateSelections(currentSelections.options, updatedSubcategories);
    };
    
    const renderSubcategories = (option) => {
        // Check if subcategories for the option exist before trying to map over them
        if (!data || !data.subcategories || !data.subcategories[option] || !currentSelections.options[option]) {
            return null;
        }
    
        return (
            <div className="subcategories">
                {data.subcategories[option].map((sub, subIndex) => (
                    <button key={`${option}-${sub}-${subIndex}`} className={currentSelections.subcategories[`${option}-${sub}`] ? 'selected-subcategory' : ''} onClick={() => handleSubcategorySelect(option, sub)}>
                        {sub}
                    </button>
                ))}
            </div>
        );
    };
    

    const renderOptions = () => {
        if (!data || !data.options) return <div>Loading...</div>;
        return (
            <div className="main-options">
                {data.options.map((option, index) => (
                    <button key={`option-${index}`} className={currentSelections.options[option] ? 'selected-option' : ''} onClick={() => handleOptionSelect(option)}>
                        {option}
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div className="section-container">
            <h2>{title}</h2>
            <div className="options-container">
                {renderOptions()}
                {data && data.options && data.options.map(renderSubcategories)}
                <button onClick={onAddCustomOption}>
                    <Icon path={mdiPlus} size={1} /> Don't See Your Interests?
                </button>
            </div>
            <div className="controls">
                {onBack && <button className="controls-left" onClick={onBack}>Back</button>}
                
                {/* Render the Next button only if it's not the last section */}
                {!isLastSection && (
                    <button className="controls-right" onClick={onNext}>Next</button>
                )}
            </div>
            <p className="disclaimer">All sections are optional</p>
        </div>
    );
};

export default SectionContainer;