import { Storage } from 'aws-amplify';

const PrivateUserStorage = {
    uploadFile: async (file, key) => {
        try {
            // Use provided key or default to file name
            const uploadKey = key || `${file.name}`;
            const result = await Storage.put(uploadKey, file, {
                level: 'private',
                contentType: file.type
            });

            console.log('File uploaded successfully:', result);
            return result;
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    },
    // Get a URL to access a file from the user's private folder
    getFileUrl: async (filename) => {


        try {
            const key = `${filename}`;
            const url = await Storage.get(key, {
                level: 'private'
            });

            console.log('URL for file:', url);
            return url;
        } catch (error) {
            console.error('Error getting file URL:', error);
            throw error;
        }
    },

    // List all files in the user's private folder
    listFiles: async () => {

        try {
            const result = await Storage.list(`/`, {
                level: 'private'
            });

            console.log('Files in private folder:', result);
            return result;
        } catch (error) {
            console.error('Error listing files:', error);
            throw error;
        }
    },
    listDataSourceFiles: async (name) => {

        try {
            const result = await Storage.list(`${name}`, {
                level: 'private'
            });

            console.log('Files in private folder:', result);
            return result;
        } catch (error) {
            console.error('Error listing files:', error);
            throw error;
        }
    }
};

export default PrivateUserStorage;
