import UserInterests from "../components/UserInterests";
import ProfileCustomization from "../components/UserProfileCustomization";

const CustomizeProfile = () => {
    return (
        <div>
            <h1>Customize Your Profile</h1>
            <section className="section">
                <ProfileCustomization />
            </section>
            <section className="section">
                <UserInterests />
            </section>
        </div>
    )
};
export default CustomizeProfile;