import React, { useState, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import { mdiAccountEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import '../styles/ProfileCustomization.css';
import FileUpload from './FileUpload';


const ProfileCustomization = () => {
    const [profileImage, setProfileImage] = useState(null);

    const handleFileUpload = useCallback(async (file) => {
        if (!file) return;

        // Check file size and file type
        if (file.size > 2097152) {
            alert("File size should be less than 2MB.");
            return;
        }
        if (!['image/png', 'image/svg+xml', 'image/jpeg'].includes(file.type)) {
            alert("Please select a PNG, SVG, or JPG image.");
            return;
        }
        const key = `profileImages/${file.name}`
        try {
            const result = await Storage.put(key, file, {
                contentType: file.type,
                level: 'private'
            });
            setProfileImage(result.key);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }, []);

    return (
        <div className="profile-customization">
            <div className="profile-image-container">
                {profileImage && <img src="" alt="Profile" />}
                <div className="profile-image-upload">
                    <label htmlFor="imageUpload" className="upload-label">
                        <Icon path={mdiAccountEdit} size={1} />
                        <span>Upload Profile Image</span>
                    </label>
                    <FileUpload onFileSelected={handleFileUpload} />
                </div>
            </div>
            <div>
                {/* Additional customization options can go here */}
            </div>
        </div>
    );
};

export default ProfileCustomization;
