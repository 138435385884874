import React, { useState, useEffect } from 'react';
import { mdiFileUpload } from '@mdi/js'; // Importing the mdi icon
import Icon from '@mdi/react'; // mdi React component
import '../styles/ChatInput.css';
import autosize from 'autosize';

const ChatInput = ({ message, onMessageChange, onSendMessage, onFileUpload }) => {

    useEffect(() => {
        const textarea = document.querySelector('textarea');
        autosize(textarea);

        return () => {
            if (textarea) autosize.destroy(textarea);
        };
    }, []);

    useEffect(() => {
        autosize.update(document.querySelector('textarea'));
    }, [message]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onMessageChange(e.target.value); 
        onSendMessage(e.target.value);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            onFileUpload(file);
        }
    };

    return (
        <form className="chat-input" onSubmit={handleSubmit}>
            <label htmlFor="file-upload" className="file-upload-button">
                <Icon path={mdiFileUpload} size={1} />
            </label>
            <textarea
                value={message}
                onChange={(e) => onMessageChange(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type a message... "
            />
            <input 
                type="file" 
                id="file-upload" 
                style={{ display: 'none' }} 
                onChange={handleFileUpload}
            />

            <button type="submit">Send</button>
        </form>
    );
};

export default ChatInput;
