import React, { useState } from 'react';
import PrivateUserStorage from '../utils/PrivateUserStorage';
import '../styles/FileUpload.css'


const FileUpload = () => {
    const [uploadTask, setUploadTask] = useState(null);


    const handleFileUpload = async (file) => {
        const task = await PrivateUserStorage.uploadFile(file);
        setUploadTask(task);
    };
    
    const pauseUpload = () => {
        if (uploadTask && uploadTask.pause) {
            uploadTask.pause();
        }
    };

    const resumeUpload = () => {
        if (uploadTask && uploadTask.resume) {
            uploadTask.resume();
        }
    };

    const cancelUpload = () => {
        if (uploadTask && uploadTask.cancel) {
            uploadTask.cancel();
        }
    };

    return (
        <div>
            <div className="upload-controls">
                <input
                    type="file"
                    onChange={(e) => handleFileUpload(e.target.files[0])}
                    disabled={uploadTask !== null}
                />
                <button className="control-button" onClick={pauseUpload} disabled={!uploadTask}>Pause</button>
                <button className="control-button" onClick={resumeUpload} disabled={!uploadTask}>Resume</button>
                <button className="control-button" onClick={cancelUpload} disabled={!uploadTask}>Cancel</button>
            </div>
        </div>
    );
};
export default FileUpload;
