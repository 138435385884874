import React, { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify'; // Import DOMPurify
import '../styles/ChatMessages.css';
import MarkdownToJsx from 'markdown-to-jsx';
import hljs from 'highlight.js';
import { languageIcons } from '../data/languageIcons';
import { mdiContentCopy } from '@mdi/js';
import { Icon } from '@mdi/react';

const ChatMessages = ({ currentChat }) => {
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        
        document.querySelectorAll('pre code').forEach((block) => {
            hljs.highlightBlock(block);
        });
    }, [currentChat.messages]); // Re-run when messages change

    // Function to handle copying code to clipboard
    const copyToClipboard = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            console.log("Copied to clipboard successfully!");
        }, (err) => {
            console.error('Error copying to clipboard: ', err);
        });
    };

    useEffect(() => {

        // Apply highlighting after rendering
        document.querySelectorAll('pre code').forEach((block) => {
            if (block.dataset.highlighted) {
                delete block.dataset.highlighted;
            }
            hljs.highlightElement(block);
        });
    }, [currentChat.messages]);

    const formatCodeBlock = (codeContent, language) => {
        const languageIcon = languageIcons[language] || 'mdiCodeBraces';
        const formattedCodeContent = '\n' + codeContent;
        return (
            <div className="code-container">
                <div className="code-ribbon">
                    <Icon path={languageIcon} size={1} className="language-icon" />
                    <button className="copy-button" onClick={() => copyToClipboard(formattedCodeContent)}>
                        <Icon path={mdiContentCopy} size={0.7} />
                    </button>
                </div>
                <pre><code className={`language-${language}`}>{formattedCodeContent}</code></pre>
            </div>
        );
    };

    const formatMessageStream = (sender, content) => {
        if (sender === 'ai') {
            const parts = [];
            const splitContent = content.split(/(```\w+\n[\s\S]*?\n```)/);

            splitContent.forEach(part => {
                const codeBlockMatch = part.match(/```(\w+)\n([\s\S]*?)\n```/);
                if (codeBlockMatch) {
                    const language = codeBlockMatch[1];
                    const codeContent = codeBlockMatch[2];
                    parts.push(formatCodeBlock(codeContent, language));
                } else {
                    // Treat as plain text
                    parts.push(<MarkdownToJsx>{part}</MarkdownToJsx>);
                }
            });
            return parts;
        }
        // Use DOMPurify to sanitize content
        return <span>{DOMPurify.sanitize(content)}</span>;
    };
    
    const formatMessageContent = (sender, content) => {
        // Only format code blocks for AI messages
        if (sender === 'ai') {
            return formatMessageStream(sender, content);
        }
        // For user messages, render content as plain text or Markdown
        
        return <span>{content}</span>;
    };
    
   // Function to decide which formatting to use based on message type
    const formatMessage = (msg) => {
        return msg.type === 'stream' 
               ? formatMessageStream(msg.sender, msg.content)
               : formatMessageContent(msg.sender, msg.content);
    };


    
    return (
        <div className="chat-messages initial-margin" ref={messagesContainerRef}>
            {currentChat.messages.map((msg, index) => (
                <div key={msg.id || index} className="message">
                    <span className="message-author">{msg.sender}</span>
                    <div className="message-content">
                        {formatMessage(msg)}
                    </div>
                </div>
            ))}
            <div ref={messagesEndRef} />
        </div>
    );
};

export default ChatMessages;