import React, { useState, useEffect } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries';

import '../styles/ChatHistorySidebar.css';
import { Icon } from '@mdi/react';
import { mdiChatPlus, mdiListBox } from '@mdi/js';

const ChatHistorySidebar = ({ onCreateNewChat, latestTopic, currentSessionId, onSessionSelect, userId, setCurrentSessionId }) => {
    const [chatHistorySessions, setChatHistorySessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchChatHistorySessions();
    }, []);

    useEffect(() => {
        if (latestTopic) {
            streamTopic(latestTopic);
        }
    }, [latestTopic]);


    const streamTopic = (latestTopic) => {
        const topic = latestTopic.topic;
        const newSessionId = latestTopic.session; // Assuming you have the session ID here
    
        let currentText = '';
        let index = 0;
        const intervalId = setInterval(() => {
            if (index < topic.length) {
                currentText += topic[index++];
                setChatHistorySessions(prevSessions => {
                    if (index === 1) {
                        // Add new session at the beginning when starting
                        return [{ id: newSessionId, topic: currentText }, ...prevSessions];
                    } else {
                        // Update the topic of the new session
                        const updatedSessions = [...prevSessions];
                        updatedSessions[0].topic = currentText;
                        return updatedSessions;
                    }
                });
            } else {
                clearInterval(intervalId);
            }
        }, 50);
    };
    
    const fetchChatHistorySessions = async () => {
        setIsLoading(true);
        const fetchedUserId = await Auth.currentAuthenticatedUser();
        const identity = fetchedUserId.userId;
        console.log("chat history user id: ", identity)
        try {
            const response = await API.graphql(graphqlOperation(queries.listChatSessionHistories, {filter: { 
                owner: {
                    eq: identity
                  }
              }
            }));

            // I'm bringing this array up to the sorting gym to work out
            const sortedSessions = response.data.listChatSessionHistories.items.sort((a, b) => {
                // Convert timestamps to date objects, then to UTC milliseconds, and finally sort in descending order
                return new Date(b.createdAt) - new Date(a.createdAt);
            });

            setChatHistorySessions(sortedSessions);
        } catch (error) {
            console.error("Error fetching chat history sessions:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleSessionClick = async (sessionId) => {
        console.log("Session clicked:", sessionId);
        try {
            const filter = {
                owner: { eq: userId }, // Ensure userId is correctly obtained
                session: { eq: sessionId },
            };
            const response = await API.graphql(graphqlOperation(queries.listChatMessageHistories, { filter: filter }));
            const messages = response.data.listChatMessageHistories.items;
            messages.sort((a, b) => a.messageID - b.messageID); // Sorting based on messageID
    
            onSessionSelect({ id: sessionId, messages }); // Pass the sorted messages to the parent component
            setCurrentSessionId(sessionId);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };
    

    return (
        <div className='chat-history-sidebar'>
            <div className='chat-side-panel'>
                <h2 className='chat-history-header'>
                    <Icon path={mdiListBox} size={1} />Chat History 
                </h2>
            </div>

            <button onClick={onCreateNewChat} className="new-chat-button">
                <Icon path={mdiChatPlus} size={1} />New Chat
            </button>

            {isLoading ? (
                <p>Loading chat history...</p>
            ) : chatHistorySessions.length > 0 ? (
                chatHistorySessions.map(session => (
                    <button key={session.id}
                            onClick={() => handleSessionClick(session.id)}
                            className={`session-button ${session.id === currentSessionId ? 'active-session' : ''}`}>

                        <span>{session.emoji || '💬' } {session.topic || 'Untitled Session'}</span>
                    </button>
                ))
            ) : (
                <p>No previous conversations.</p>
            )}
        </div>
    );
};

export default ChatHistorySidebar;