import React, { useState, useEffect, useCallback } from 'react';
import ProfileOptions from '../data/ProfileOptions.json';
import SectionContainer from '../containers/SectionContainer';
import { saveUserProfile, formatProfileData, fetchUserProfile } from '../utils/ProfileAggregate';
import { Auth } from 'aws-amplify'; // Import Auth from AWS Amplify

const UserInterests = () => {
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [currentData, setCurrentData] = useState({});
    const [userHasProfile, setUserHasProfile] = useState(false);
    const [currentSelections, setCurrentSelections] = useState({ options: {}, subcategories: {} });
    const [userProfile, setUserProfile] = useState(null);
    const [user, setUser] = useState(null); // Add a state for the user

    const saveData = useCallback(async () => {
        if (user && userProfile) {
            await saveUserProfile(user["attributes"]["sub"], user["username"], userProfile);
        }
        console.log("saved: ", userProfile)
    }, [user, userProfile]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userInfo = await Auth.currentAuthenticatedUser();
                setUser(userInfo);
            } catch (error) {
                console.error('Error fetching user: ', error);
            }
        };

        fetchUser();
    }, []);

    useEffect(() => {
        const loadUserProfile = async () => {
            if (user) {
                const userId = user["attributes"]["sub"];
                const profile = await fetchUserProfile(userId);
                if (profile) {
                    setUserHasProfile(true);
                    const formattedSelections = convertToSelectionsFormat(profile.preferences);
                    setCurrentSelections(formattedSelections);
                    setUserProfile(profile); // Store the fetched user profile
                }
            }
        };
        if (user) {
            loadUserProfile();
        }
    }, [user]);


    const updateSelections = useCallback((newOptions, newSubcategories) => {
        setCurrentSelections({ options: newOptions, subcategories: newSubcategories });
        if (userProfile) {
            userProfile.preferences = formatProfileData({ options: newOptions, subcategories: newSubcategories });
        }
    }, [userProfile]);
    
    const convertToSelectionsFormat = (preferences) => {
        const formattedSelections = { options: {}, subcategories: {} };
        preferences.forEach(pref => {
            if (pref.includes('-')) {
                const [option, subcategory] = pref.split('-');
                formattedSelections.subcategories[`${option}-${subcategory}`] = true;
                formattedSelections.options[option] = true;
            } else {
                formattedSelections.options[pref] = true;
            }
        });
        return formattedSelections;
    };

    useEffect(() => {
        const sectionKeys = Object.keys(ProfileOptions.ProfileOptionsData);
        const initialData = sectionKeys[currentSectionIndex]
            ? ProfileOptions.ProfileOptionsData[sectionKeys[currentSectionIndex]]
            : { options: [], subcategories: {} };
        setCurrentData(initialData);
    }, [currentSectionIndex]);

    const handleSectionChange = useCallback((newSectionIndex) => {
        setCurrentSectionIndex(newSectionIndex);
    }, []);

    const handleNextSection = () => {
        if (currentSectionIndex < Object.keys(ProfileOptions.ProfileOptionsData).length - 1) {
            handleSectionChange(currentSectionIndex + 1);
        }
    };

    const handleBackSection = () => {
        if (currentSectionIndex > 0) {
            handleSectionChange(currentSectionIndex - 1);
        }
    };

    useEffect(() => {
        return () => {
            if (userHasProfile) {
                saveData();
            }
        };
    }, [saveData, userHasProfile]);

    return (
        <div className="customize-profile">
            {currentData && Object.keys(ProfileOptions.ProfileOptionsData).length > 0 && (
                <SectionContainer
                    key={currentSectionIndex}
                    title={Object.keys(ProfileOptions.ProfileOptionsData)[currentSectionIndex]}
                    data={currentData}
                    currentSelections={currentSelections}
                    onUpdateSelections={updateSelections}
                    onNext={handleNextSection}
                    onBack={handleBackSection}
                    isLastSection={currentSectionIndex === Object.keys(ProfileOptions.ProfileOptionsData).length - 1}
                />
            )}
        </div>
    );
};

export default UserInterests;
