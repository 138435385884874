import {
    mdiLanguageJavascript, mdiLanguagePython, mdiLanguageHtml5,
    mdiLanguageCss3, mdiLanguagePhp, mdiLanguageJava,
    mdiLanguageCsharp, mdiLanguageCpp, mdiLanguageSwift,
    mdiLanguageRuby, mdiLanguageGo, mdiLanguageKotlin,
    mdiLanguageTypescript, mdiLanguageRust, mdiLanguageXaml,
    mdiBash, mdiLanguageMarkdown, mdiCodeJson, mdiReact } from '@mdi/js';

export const languageIcons = {
    javascript: mdiLanguageJavascript,
    jsx: mdiReact,
    python: mdiLanguagePython,
    html: mdiLanguageHtml5,
    css: mdiLanguageCss3,
    php: mdiLanguagePhp,
    java: mdiLanguageJava,
    csharp: mdiLanguageCsharp,
    cpp: mdiLanguageCpp,
    swift: mdiLanguageSwift,
    ruby: mdiLanguageRuby,
    go: mdiLanguageGo,
    kotlin: mdiLanguageKotlin,
    typescript: mdiLanguageTypescript,
    rust: mdiLanguageRust,
    xaml: mdiLanguageXaml,
    bash: mdiBash,
    markdown: mdiLanguageMarkdown,
    json: mdiCodeJson,
    // ... More mappings here
};