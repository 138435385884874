/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSessionId = /* GraphQL */ `
  query CreateSessionId($input: createSessionIdInput!) {
    createSessionId(input: $input) {
      session
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      username
      preferences
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        preferences
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserProfiles = /* GraphQL */ `
  query SyncUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        preferences
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDataSource = /* GraphQL */ `
  query GetDataSource($id: ID!) {
    getDataSource(id: $id) {
      id
      userId
      name
      topic
      includeSources
      excludeSources
      urls
      files
      customDataRequest
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDataSources = /* GraphQL */ `
  query ListDataSources(
    $filter: ModelDataSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        name
        topic
        includeSources
        excludeSources
        urls
        files
        customDataRequest
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDataSources = /* GraphQL */ `
  query SyncDataSources(
    $filter: ModelDataSourceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDataSources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        name
        topic
        includeSources
        excludeSources
        urls
        files
        customDataRequest
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomChatModels = /* GraphQL */ `
  query GetCustomChatModels($id: ID!) {
    getCustomChatModels(id: $id) {
      id
      userId
      name
      purpose
      persona
      model_ids {
        chat
        backend
        image
        tts
        stt
        embedding
        __typename
      }
      custom_instructions {
        chat
        backend
        image
        tts
        stt
        __typename
      }
      dataSources
      integrations
      custom_tools {
        action
        IntegrationID
        description
        whenToUse
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCustomChatModels = /* GraphQL */ `
  query ListCustomChatModels(
    $filter: ModelCustomChatModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomChatModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        name
        purpose
        persona
        dataSources
        integrations
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCustomChatModels = /* GraphQL */ `
  query SyncCustomChatModels(
    $filter: ModelCustomChatModelsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomChatModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        name
        purpose
        persona
        dataSources
        integrations
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatModel = /* GraphQL */ `
  query GetChatModel($id: ID!) {
    getChatModel(id: $id) {
      id
      model
      host
      provider
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatModels = /* GraphQL */ `
  query ListChatModels(
    $filter: ModelChatModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        host
        provider
        maxTokens
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatModels = /* GraphQL */ `
  query SyncChatModels(
    $filter: ModelChatModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        model
        host
        provider
        maxTokens
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBackendModel = /* GraphQL */ `
  query GetBackendModel($id: ID!) {
    getBackendModel(id: $id) {
      id
      model
      host
      provider
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBackendModels = /* GraphQL */ `
  query ListBackendModels(
    $filter: ModelBackendModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBackendModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        host
        provider
        maxTokens
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBackendModels = /* GraphQL */ `
  query SyncBackendModels(
    $filter: ModelBackendModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBackendModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        model
        host
        provider
        maxTokens
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEmbeddingModel = /* GraphQL */ `
  query GetEmbeddingModel($id: ID!) {
    getEmbeddingModel(id: $id) {
      id
      model
      provider
      host
      vectorDimensions
      maxTokens
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEmbeddingModels = /* GraphQL */ `
  query ListEmbeddingModels(
    $filter: ModelEmbeddingModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmbeddingModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        provider
        host
        vectorDimensions
        maxTokens
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEmbeddingModels = /* GraphQL */ `
  query SyncEmbeddingModels(
    $filter: ModelEmbeddingModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmbeddingModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        model
        provider
        host
        vectorDimensions
        maxTokens
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImageModel = /* GraphQL */ `
  query GetImageModel($id: ID!) {
    getImageModel(id: $id) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImageModels = /* GraphQL */ `
  query ListImageModels(
    $filter: ModelImageModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        host
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImageModels = /* GraphQL */ `
  query SyncImageModels(
    $filter: ModelImageModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImageModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        model
        host
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTtsModel = /* GraphQL */ `
  query GetTtsModel($id: ID!) {
    getTtsModel(id: $id) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTtsModels = /* GraphQL */ `
  query ListTtsModels(
    $filter: ModelTtsModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTtsModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        host
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTtsModels = /* GraphQL */ `
  query SyncTtsModels(
    $filter: ModelTtsModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTtsModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        model
        host
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSttModel = /* GraphQL */ `
  query GetSttModel($id: ID!) {
    getSttModel(id: $id) {
      id
      model
      host
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSttModels = /* GraphQL */ `
  query ListSttModels(
    $filter: ModelSttModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSttModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        host
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSttModels = /* GraphQL */ `
  query SyncSttModels(
    $filter: ModelSttModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSttModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        model
        host
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAPI = /* GraphQL */ `
  query GetAPI($id: ID!) {
    getAPI(id: $id) {
      id
      description
      category
      openapi_json_url
      openapi_yaml_url
      license
      openapiVer
      link
      updated
      externalDocs
      termsofService
      contact
      title
      clientRegistation
      provider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAPIS = /* GraphQL */ `
  query ListAPIS(
    $filter: ModelAPIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAPIS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        category
        openapi_json_url
        openapi_yaml_url
        license
        openapiVer
        link
        updated
        externalDocs
        termsofService
        contact
        title
        clientRegistation
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAPIS = /* GraphQL */ `
  query SyncAPIS(
    $filter: ModelAPIFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAPIS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        description
        category
        openapi_json_url
        openapi_yaml_url
        license
        openapiVer
        link
        updated
        externalDocs
        termsofService
        contact
        title
        clientRegistation
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchAPIS = /* GraphQL */ `
  query SearchAPIS(
    $filter: SearchableAPIFilterInput
    $sort: [SearchableAPISortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAPIAggregationInput]
  ) {
    searchAPIS(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        description
        category
        openapi_json_url
        openapi_yaml_url
        license
        openapiVer
        link
        updated
        externalDocs
        termsofService
        contact
        title
        clientRegistation
        provider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getChatHistorySession = /* GraphQL */ `
  query GetChatHistorySession($id: ID!) {
    getChatHistorySession(id: $id) {
      id
      owner
      topic
      session
      timestamp
      messages {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatHistorySessions = /* GraphQL */ `
  query ListChatHistorySessions(
    $filter: ModelChatHistorySessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatHistorySessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatHistorySessions = /* GraphQL */ `
  query SyncChatHistorySessions(
    $filter: ModelChatHistorySessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatHistorySessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatHistoryMessage = /* GraphQL */ `
  query GetChatHistoryMessage($id: ID!) {
    getChatHistoryMessage(id: $id) {
      id
      owner
      content
      sender
      context
      type
      backend
      timestamp
      session {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatHistoryMessages = /* GraphQL */ `
  query ListChatHistoryMessages(
    $filter: ModelChatHistoryMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatHistoryMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content
        sender
        context
        type
        backend
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatHistoryMessages = /* GraphQL */ `
  query SyncChatHistoryMessages(
    $filter: ModelChatHistoryMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatHistoryMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        content
        sender
        context
        type
        backend
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      username
      UserProfile {
        id
        username
        preferences
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ChatHistorySessions {
        nextToken
        startedAt
        __typename
      }
      DataSources {
        nextToken
        startedAt
        __typename
      }
      Integrations {
        nextToken
        startedAt
        __typename
      }
      customChatModels {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserProfileId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        username
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserProfileId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        username
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserProfileId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatMessageHistory = /* GraphQL */ `
  query GetChatMessageHistory($session: ID!, $messageID: Int!) {
    getChatMessageHistory(session: $session, messageID: $messageID) {
      owner
      content
      sender
      context
      type
      timestamp
      messageID
      session
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatMessageHistories = /* GraphQL */ `
  query ListChatMessageHistories(
    $session: ID
    $messageID: ModelIntKeyConditionInput
    $filter: ModelChatMessageHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatMessageHistories(
      session: $session
      messageID: $messageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        content
        sender
        context
        type
        timestamp
        messageID
        session
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatMessageHistories = /* GraphQL */ `
  query SyncChatMessageHistories(
    $filter: ModelChatMessageHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatMessageHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        owner
        content
        sender
        context
        type
        timestamp
        messageID
        session
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatSessionHistory = /* GraphQL */ `
  query GetChatSessionHistory($id: ID!) {
    getChatSessionHistory(id: $id) {
      id
      owner
      topic
      emoji
      session
      timestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatSessionHistories = /* GraphQL */ `
  query ListChatSessionHistories(
    $filter: ModelChatSessionHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatSessionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topic
        emoji
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatSessionHistories = /* GraphQL */ `
  query SyncChatSessionHistories(
    $filter: ModelChatSessionHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatSessionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        topic
        emoji
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getModelNotes = /* GraphQL */ `
  query GetModelNotes($session: ID!, $name: String!) {
    getModelNotes(session: $session, name: $name) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listModelNotes = /* GraphQL */ `
  query ListModelNotes(
    $session: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelModelNotesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listModelNotes(
      session: $session
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        name
        messageID
        note
        model
        thought
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncModelNotes = /* GraphQL */ `
  query SyncModelNotes(
    $filter: ModelModelNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncModelNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        name
        messageID
        note
        model
        thought
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotesModel = /* GraphQL */ `
  query GetNotesModel($session: ID!, $userId: String!) {
    getNotesModel(session: $session, userId: $userId) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNotesModels = /* GraphQL */ `
  query ListNotesModels(
    $session: ID
    $userId: ModelStringKeyConditionInput
    $filter: ModelNotesModelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotesModels(
      session: $session
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        name
        messageID
        note
        model
        thought
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotesModels = /* GraphQL */ `
  query SyncNotesModels(
    $filter: ModelNotesModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotesModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        name
        messageID
        note
        model
        thought
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotes = /* GraphQL */ `
  query GetNotes($session: ID!, $userId: String!, $messageID: Int!) {
    getNotes(session: $session, userId: $userId, messageID: $messageID) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $session: ID
    $userIdMessageID: ModelNotesPrimaryCompositeKeyConditionInput
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotes(
      session: $session
      userIdMessageID: $userIdMessageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        name
        messageID
        note
        model
        thought
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotes = /* GraphQL */ `
  query SyncNotes(
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        name
        messageID
        note
        model
        thought
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getModelContext = /* GraphQL */ `
  query GetModelContext($session: ID!, $name: String!) {
    getModelContext(session: $session, name: $name) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listModelContexts = /* GraphQL */ `
  query ListModelContexts(
    $session: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelModelContextFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listModelContexts(
      session: $session
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        name
        relation
        context
        timestamp
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncModelContexts = /* GraphQL */ `
  query SyncModelContexts(
    $filter: ModelModelContextFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncModelContexts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        name
        relation
        context
        timestamp
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContextModel = /* GraphQL */ `
  query GetContextModel($session: ID!, $userId: String!) {
    getContextModel(session: $session, userId: $userId) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContextModels = /* GraphQL */ `
  query ListContextModels(
    $session: ID
    $userId: ModelStringKeyConditionInput
    $filter: ModelContextModelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContextModels(
      session: $session
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        name
        relation
        context
        timestamp
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContextModels = /* GraphQL */ `
  query SyncContextModels(
    $filter: ModelContextModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContextModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        name
        relation
        context
        timestamp
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContext = /* GraphQL */ `
  query GetContext($session: ID!, $userId: String!, $messageID: Int!) {
    getContext(session: $session, userId: $userId, messageID: $messageID) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContexts = /* GraphQL */ `
  query ListContexts(
    $session: ID
    $userIdMessageID: ModelContextPrimaryCompositeKeyConditionInput
    $filter: ModelContextFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContexts(
      session: $session
      userIdMessageID: $userIdMessageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        name
        relation
        context
        timestamp
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContexts = /* GraphQL */ `
  query SyncContexts(
    $filter: ModelContextFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContexts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        name
        relation
        context
        timestamp
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getModelInstruct = /* GraphQL */ `
  query GetModelInstruct($session: ID!, $instruction: String!) {
    getModelInstruct(session: $session, instruction: $instruction) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listModelInstructs = /* GraphQL */ `
  query ListModelInstructs(
    $session: ID
    $instruction: ModelStringKeyConditionInput
    $filter: ModelModelInstructFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listModelInstructs(
      session: $session
      instruction: $instruction
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        instruction
        reason
        directTo
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncModelInstructs = /* GraphQL */ `
  query SyncModelInstructs(
    $filter: ModelModelInstructFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncModelInstructs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        instruction
        reason
        directTo
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInstructModel = /* GraphQL */ `
  query GetInstructModel($session: ID!, $userId: String!) {
    getInstructModel(session: $session, userId: $userId) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInstructModels = /* GraphQL */ `
  query ListInstructModels(
    $session: ID
    $userId: ModelStringKeyConditionInput
    $filter: ModelInstructModelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstructModels(
      session: $session
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        instruction
        reason
        directTo
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInstructModels = /* GraphQL */ `
  query SyncInstructModels(
    $filter: ModelInstructModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstructModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        instruction
        reason
        directTo
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInstruct = /* GraphQL */ `
  query GetInstruct($session: ID!, $userId: String!, $messageID: Int!) {
    getInstruct(session: $session, userId: $userId, messageID: $messageID) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInstructs = /* GraphQL */ `
  query ListInstructs(
    $session: ID
    $userIdMessageID: ModelInstructPrimaryCompositeKeyConditionInput
    $filter: ModelInstructFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstructs(
      session: $session
      userIdMessageID: $userIdMessageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        instruction
        reason
        directTo
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInstructs = /* GraphQL */ `
  query SyncInstructs(
    $filter: ModelInstructFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstructs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        instruction
        reason
        directTo
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomTool = /* GraphQL */ `
  query GetCustomTool($id: ID!) {
    getCustomTool(id: $id) {
      action
      actionInput {
        description
        __typename
      }
      actionInputMapping {
        param
        __typename
      }
      IntegrationID
      description
      whenToUse
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCustomTools = /* GraphQL */ `
  query ListCustomTools(
    $filter: ModelCustomToolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        action
        IntegrationID
        description
        whenToUse
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCustomTools = /* GraphQL */ `
  query SyncCustomTools(
    $filter: ModelCustomToolFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomTools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        action
        IntegrationID
        description
        whenToUse
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIntegration = /* GraphQL */ `
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      id
      name
      description
      baseUrl
      authType
      authDetails
      headers
      parameters
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIntegrations = /* GraphQL */ `
  query ListIntegrations(
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        baseUrl
        authType
        authDetails
        headers
        parameters
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIntegrations = /* GraphQL */ `
  query SyncIntegrations(
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        baseUrl
        authType
        authDetails
        headers
        parameters
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWorkflow = /* GraphQL */ `
  query GetWorkflow($id: ID!) {
    getWorkflow(id: $id) {
      id
      name
      outputFormat
      integrations
      inputFormat
      goal
      tone
      forWho
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWorkflows = /* GraphQL */ `
  query ListWorkflows(
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkflows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        outputFormat
        integrations
        inputFormat
        goal
        tone
        forWho
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWorkflows = /* GraphQL */ `
  query SyncWorkflows(
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        outputFormat
        integrations
        inputFormat
        goal
        tone
        forWho
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDefaultWorkflow = /* GraphQL */ `
  query GetDefaultWorkflow($id: ID!) {
    getDefaultWorkflow(id: $id) {
      id
      name
      outputFormat
      integrations
      inputFormat
      goal
      tone
      forWho
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDefaultWorkflows = /* GraphQL */ `
  query ListDefaultWorkflows(
    $filter: ModelDefaultWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDefaultWorkflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        outputFormat
        integrations
        inputFormat
        goal
        tone
        forWho
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDefaultWorkflows = /* GraphQL */ `
  query SyncDefaultWorkflows(
    $filter: ModelDefaultWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDefaultWorkflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        outputFormat
        integrations
        inputFormat
        goal
        tone
        forWho
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const generateOpenAPIUI = /* GraphQL */ `
  query GenerateOpenAPIUI($input: OpenAPIUiGenInput!) {
    generateOpenAPIUI(input: $input) {
      response
      __typename
    }
  }
`;
export const CallExternalApi = /* GraphQL */ `
  query CallExternalApi($input: CallExternalApiInput!) {
    CallExternalApi(input: $input) {
      response
      __typename
    }
  }
`;
export const recognizeTextFromImage = /* GraphQL */ `
  query RecognizeTextFromImage($input: RecognizeTextFromImageInput!) {
    recognizeTextFromImage(input: $input)
  }
`;
export const recognizeLabelsFromImage = /* GraphQL */ `
  query RecognizeLabelsFromImage($input: RecognizeLabelsFromImageInput!) {
    recognizeLabelsFromImage(input: $input)
  }
`;
