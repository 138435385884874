import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiClose, mdiRadioboxMarked, mdiRadioboxBlank } from '@mdi/js';
import Modal from '../components/Modal';

import '../styles/ChatModel.css';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { motion, AnimatePresence } from 'framer-motion';

const ChatModel = () => {
    const [dataSources, setDataSources] = useState([]);
    const [selectedDataSources, setSelectedDataSources] = useState([]);
    const [customChatModels, setCustomChatModels] = useState([]);
    const [chatModelData, setChatModelData] = useState([]);
    const [backendModelData, setBackendModelData] = useState([]);
    const [embeddingModelData, setEmbeddingModelData] = useState([]);
    const [imageModelData, setImageModelData] = useState([]);
    const [ttsModelData, setTtsModelData] = useState([]);
    const [sttModelData, setSttModelData] = useState([]);
    const [integrations, setIntegrations] = useState([]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        fetchChatModels();
        fetchDataSources();
        fetchIntegrations();
        fetchModelData();
    }, []);
    const fetchModelData = async () => {
        // Fetch Chat Models
        const chatModels = await API.graphql(graphqlOperation(queries.listChatModels));
        console.log("Chat Models: ", chatModels)
        setChatModelData(chatModels.data.listChatModels.items);

        // Fetch Backend Models
        const backendModels = await API.graphql(graphqlOperation(queries.listBackendModels));
        console.log("Backend Models: ", backendModels)
        setBackendModelData(backendModels.data.listBackendModels.items);

        // Fetch Embedding Models
        const embeddingModels = await API.graphql(graphqlOperation(queries.listEmbeddingModels));
        console.log("Embedding Models: ", embeddingModels)
        setEmbeddingModelData(embeddingModels.data.listEmbeddingModels.items);

        const imageModels = await API.graphql(graphqlOperation(queries.listImageModels));
        console.log("Image Models: ", imageModels)
        setImageModelData(imageModels.data.listImageModels.items);

        const sttModels = await API.graphql(graphqlOperation(queries.listSttModels));
        console.log("Stt Models: ", sttModels)
        setSttModelData(sttModels.data.listSttModels.items);

        const ttsModels = await API.graphql(graphqlOperation(queries.listTtsModels));
        console.log("Tts Models: ", ttsModels)
        setTtsModelData(ttsModels.data.listTtsModels.items);

    };
    const fetchIntegrations = async () => {
        const credentials = await Auth.currentCredentials();
        const identityId = credentials.identityId;    
        try {
            const integrationsData = await API.graphql(graphqlOperation(queries.listIntegrations, {filter: { 
                userId: {
                    eq: identityId
                }
            }
        }));
            if (integrationsData.data.listIntegrations.items) {
                setIntegrations(integrationsData.data.listIntegrations.items);
            }
            console.log("Integrations Data: ", integrationsData)
        } catch (error) {
            console.error("Error fetching integrations:", error);
        }
    };


    const fetchDataSources = async () => {
        const credentials = await Auth.currentCredentials();
        const identityId = credentials.identityId;
        try {
            const dataSourceData = await API.graphql(graphqlOperation(queries.listDataSources, {filter: { 
                userId: {
                    eq: identityId
                }
            }
        }));
            if (dataSourceData.data.listDataSources.items) {
                setDataSources(dataSourceData.data.listDataSources.items);
            }
            console.log("Data Sources: ", dataSourceData)
        } catch (error) {
            console.error("Error fetching data sources:", error);
        }
    };


    const fetchChatModels = async () => {
        const credentials = await Auth.currentAuthenticatedUser();
        const identityId = credentials.userId;
        try {
            const chatModelsData = await API.graphql(graphqlOperation(queries.listCustomChatModels, {filter: { 
                userId: {
                    eq: identityId
                }
            }
        }));
            if (chatModelsData.data.listCustomChatModels.items) {
                setCustomChatModels(chatModelsData.data.listCustomChatModels.items);
            }
            console.log("Custom Models: ", chatModelsData)
        } catch (error) {
            console.error("Error fetching chat models:", error);
        }
    };

    const toggleDataSource = (dataSourceId) => {
        setSelectedDataSources(prevSelectedDataSources => {
            const dataSourceIndex = prevSelectedDataSources.findIndex(ds => ds.id === dataSourceId);
            if (dataSourceIndex > -1) {
                return prevSelectedDataSources.filter((_, index) => index !== dataSourceIndex);
            } else {
                const newDataSource = dataSources.find(ds => ds.id === dataSourceId);
                return [...prevSelectedDataSources, newDataSource];
            }
        });
    };
    
    const isDataSourceSelected = (dataSourceId) => {
        return selectedDataSources.some(ds => ds.id === dataSourceId);
    };
    
    const toggleIntegration = (integrationId) => {
        setSelectedIntegrations(prevSelectedIntegrations => {
            const integrationIndex = prevSelectedIntegrations.findIndex(int => int.id === integrationId);
            if (integrationIndex > -1) {
                return prevSelectedIntegrations.filter((_, index) => index !== integrationIndex);
            } else {
                const newIntegration = integrations.find(int => int.id === integrationId);
                return [...prevSelectedIntegrations, newIntegration];
            }
        });
    };
    
    const isIntegrationSelected = (integrationId) => {
        return selectedIntegrations.some(int => int.id === integrationId);
    };
    

    const [modelName, setModelName] = useState('');
    const [modelPurpose, setModelPurpose] = useState('');
    const [customInstructions, setCustomInstructions] = useState('');
    const [extraInformation, setExtraInformation] = useState('');
    const [avoidSaying, setAvoidSaying] = useState('');
    const [modelPersona, setModelPersona] = useState('');
    const [selectedChatModel, setSelectedChatModel] = useState({});
    const [selectedBackendModel, setSelectedBackendModel] = useState({});
    const [selectedEmbeddingModel, setSelectedEmbeddingModel] = useState({});
    const [selectedImageModel, setSelectedImageModel] = useState({});
    const [selectedTtsModel, setSelectedTtsModel] = useState({});
    const [selectedSttModel, setSelectedSttModel] = useState({});
    const [chatModelInstructions, setChatModelInstructions] = useState('');
    const [backendModelInstructions, setBackendModelInstructions] = useState('');
    const [imageModelInstructions, setImageModelInstructions] = useState('');
    const [ttsModelInstructions, setTtsModelInstructions] = useState('');
    const [sttModelInstructions, setSttModelInstructions] = useState('');
    const [editingModel, setEditingModel] = useState(null); // State to track the model being edited
    const [selectedIntegrations, setSelectedIntegrations] = useState([]);

    const handleCreateChatModel = () => {
        setCreateModalOpen(true);
    };

    const handleSelectModel = (category, model) => {
        if (category === 'chat') setSelectedChatModel(model);
        else if (category === 'backend') setSelectedBackendModel(model);
        else if (category === 'embedding') setSelectedEmbeddingModel(model);
        else if (category === 'image') setSelectedImageModel(model);
        else if (category === 'stt') setSelectedSttModel(model);
        else if (category === 'tts') setSelectedTtsModel(model);
    };
    const handleEditChatModel = (model) => {
        setEditingModel(model); // Set the model to be edited
    
        // Populate form fields with the selected model's details
        setModelName(model.name);
        setModelPurpose(model.purpose);
        setModelPersona(model.persona);
        setCustomInstructions(model.customInstructions);
        setExtraInformation(model.extraInformation);
        setAvoidSaying(model.avoidSaying);
        setChatModelInstructions(model.chatModelInstructions);
        setBackendModelInstructions(model.backendModelInstructions);
        setImageModelInstructions(model.imageModelInstructions);
        setTtsModelInstructions(model.ttsModelInstructions);
        setSttModelInstructions(model.sttModelInstructions);
    
        // Select associated model IDs (if any)
        setSelectedChatModel(model.chatModel ? chatModelData.find(chatModel => chatModel.id === model.chatModel) : {});
        setSelectedBackendModel(model.backendModel ? backendModelData.find(backendModel => backendModel.id === model.backendModel) : {});
        setSelectedEmbeddingModel(model.embeddingModel ? embeddingModelData.find(embedModel => embedModel.id === model.embeddingModel) : {});
        setSelectedImageModel(model.imageModel ? imageModelData.find(imageModel => imageModel.id === model.imageModel) : {});
        setSelectedTtsModel(model.ttsModel ? ttsModelData.find(ttsModel => ttsModel.id === model.ttsModel) : {});
        setSelectedSttModel(model.sttModel ? sttModelData.find(sttModel => sttModel.id === model.sttModel) : {});
    
        // Map data sources related to the model
        const associatedDataSourceIds = model.dataSources.map(dsId => dataSources.find(ds => ds.id === dsId));
        setSelectedDataSources(associatedDataSourceIds);
    
        // Map integrations related to the model
        const associatedIntegrationIds = model.Integrations ? model.Integrations.map(intId => integrations.find(int => int.id === intId)) : [];
        setSelectedIntegrations(associatedIntegrationIds);
    
        setCreateModalOpen(true); // Open the modal in edit mode
    };
    
    
    
    const handleSubmit = async () => {
        const credentials = await Auth.currentCredentials();
        const user = await Auth.currentAuthenticatedUser();
        const identityId = credentials.identityId;
        const userId = user["attributes"]["sub"];
        
        const id = `${userId}-${identityId}-${modelName}`;
    
        const customInstructions = {
            chat: chatModelInstructions,
            backend: backendModelInstructions,
            image: imageModelInstructions,
            tts: ttsModelInstructions,
            stt: sttModelInstructions
        }
        const model_ids = {
            chat: selectedChatModel.id || null,
            backend: selectedBackendModel.id || null,
            embedding: selectedEmbeddingModel.id || null,
            image: selectedImageModel.id || null,
            tts: selectedTtsModel.id || null,
            stt: selectedSttModel.id || null
        }
        let customChatModel = {
            id: id,
            userId: userId,
            name: modelName,
            purpose: modelPurpose,
            persona: modelPersona,
            model_ids: model_ids,
            custom_instructions: customInstructions
        };
    
        // Add dataSources and Integrations as arrays of IDs
        if (selectedDataSources && selectedDataSources.length > 0) {
            customChatModel.dataSources = selectedDataSources.map(ds => ds.id);
        }
        if (selectedIntegrations && selectedIntegrations.length > 0) {
            customChatModel.Integrations = selectedIntegrations.map(int => int.id);
        }
        try {
            // Enhanced debugging: Log each field and its type
            Object.entries(customChatModel).forEach(([key, value]) => {
                console.log(`${key}: (${typeof value})`, value);
            });
            console.log("CustomChatModel: ", customChatModel)
            let response;
            if (editingModel) {
                response = await API.graphql(graphqlOperation(mutations.updateCustomChatModels, { input: customChatModel }));
                console.log("Chat Model Updated:", response);
            } else {
                response = await API.graphql(graphqlOperation(mutations.createCustomChatModels, { input: customChatModel }));
                console.log("New Chat Model Created:", response);
            }
    
            // Post submission logic...
        } catch (error) {
            console.error("Error submitting custom chat model:", error);
    
            // Log detailed error information
            if (error.errors && error.errors.length > 0) {
                error.errors.forEach(err => {
                    console.error("Error detail:", err.message);
                });
            }
        }
    };
    const listItemVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };



    return (
        <div className="chat-model-container">
            <h1>Custom Chat Models</h1>
            {customChatModels.length > 0 ? (
                <ul className="chat-models-list">
                    <AnimatePresence>
                        {customChatModels.map(model => (
                            <motion.li
                                key={model.id}
                                variants={listItemVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                className="chat-model-list-item"
                                onClick={() => handleEditChatModel(model)} // Handle click to edit model
                            >
                                {model.name} - Purpose: {model.purpose}
                                {/* You can add more details as per your data structure */}
                            </motion.li>
                        ))}
                    </AnimatePresence>
                </ul>
            ) : (
                <p>No custom chat models available. Create a new model.</p>
            )}
            <button onClick={() => { setEditingModel(null); handleCreateChatModel(); }} className="create-chat-model-button">Create Chat Model</button>
            {isCreateModalOpen && (
                <Modal onClose={() => setCreateModalOpen(false)} modalRef={modalRef}>
                    <div className="modal-header">
                        <h2>Create New Custom MultiModal Chat Model</h2>
                        <button onClick={() => setCreateModalOpen(false)}><Icon path={mdiClose} size={1} /></button>
                    </div>
                    <div className="modal-content">
                        {/* New Inputs for Name and Purpose */}
                        <div className="input-group">
                            <label>Name</label>
                            <input type="text" placeholder="Enter name for your new AI..." value={modelName} onChange={(e) => setModelName(e.target.value)} />
                        </div>
                        <div className="input-group">
                            <label>Model Purpose</label>
                            <input type="text" placeholder="Enter model purpose" value={modelPurpose} onChange={(e) => setModelPurpose(e.target.value)} />
                        </div>
                        <section className="model-section">
                            <h3>Data Sources</h3>
                            {dataSources.length > 0 ? (
                            <ul className="data-sources-list">
                                {dataSources.map(dataSource => (
                                    <li key={dataSource.id} className="data-source-item">
                                        <Icon
                                            path={isDataSourceSelected(dataSource.id) ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
                                            size={1}
                                            onClick={() => toggleDataSource(dataSource.id)}
                                        />
                                        <span className="data-source-name">{dataSource.name}</span>
                                    </li>
                                ))}
                            </ul> 
                            ) : <div className='create-ds-intgr'>
                                    <NavLink to="/data-source">Create Data Source</NavLink>
                                </div> }
                        </section>
                        <section className="model-section">
                            <h3>Integrations</h3>
                            {integrations.length > 0 ? (
                            <ul className="data-sources-list">
                                {integrations.map(integrations => (
                                    <li key={integrations.id} className="data-source-item">
                                        <Icon
                                            path={isIntegrationSelected(integrations.id) ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
                                            size={1}
                                            onClick={() => toggleIntegration(integrations.id)}
                                        />
                                        <span className="data-source-name">{integrations.name}</span>
                                    </li>
                                ))}
                            </ul>
                            ) : <div className='create-ds-intgr'>
                                <NavLink to="/integrations">Create Integrations</NavLink>
                            </div> }
                    </section>
                        {/* Chat Model Selection */}
                        <section className="model-section">
                            <h3>Chat Model Selection</h3>
                            <ModelTable 
                                models={chatModelData} 
                                selectedModel={selectedChatModel} 
                                onSelectModel={(model) => handleSelectModel('chat', model)}
                                columns={['Select', 'Model', 'Max Tokens', 'Provider', 'Host']}
                            />
                            <div className="custom-instructions">
                                <label>Custom Instructions for Chat Model</label>
                                <textarea 
                                    placeholder="Type custom instructions here..." 
                                    value={chatModelInstructions}
                                    onChange={(e) => setChatModelInstructions(e.target.value)}
                                ></textarea>
                            </div>
                        </section>

                        {/* Backend Model Selection */}
                        <section className="model-section">
                            <h3>Backend Model Selection</h3>
                            <ModelTable 
                                models={backendModelData} 
                                selectedModel={selectedBackendModel} 
                                onSelectModel={(model) => handleSelectModel('backend', model)}
                                columns={['Select', 'Model', 'Max Tokens', 'Provider', 'Host']}
                            />
                            <div className="custom-instructions">
                                <label>Custom Instructions for Backend Model</label>
                                <textarea 
                                    placeholder="Type custom instructions here..." 
                                    value={backendModelInstructions}
                                    onChange={(e) => setBackendModelInstructions(e.target.value)}
                                ></textarea>
                            </div>
                        </section>

                        {/* Embedding Model Selection */}
                        <section className="model-section">
                            <h3>Embedding Model Selection</h3>
                            <ModelTable 
                                models={embeddingModelData} 
                                selectedModel={selectedEmbeddingModel} 
                                onSelectModel={(model) => handleSelectModel('embedding', model)}
                                columns={['Select', 'Model', 'Vector Dimensions', 'Max Tokens', 'Provider', 'Host']}
                            />
                        </section>

                        {/* Image Model Selection */}
                        <section className="model-section">
                            <h3>Image Model Selection</h3>
                            <ModelTable 
                                models={imageModelData} 
                                selectedModel={selectedImageModel} 
                                onSelectModel={(model) => handleSelectModel('image', model)}
                                columns={['Select', 'Model', 'Provider', 'Host']}
                            />
                             <div className="custom-instructions">
                                <label>Custom Instructions for Image Model</label>
                                <textarea 
                                    placeholder="Type custom instructions here..." 
                                    value={imageModelInstructions}
                                    onChange={(e) => setImageModelInstructions(e.target.value)}
                                ></textarea>
                            </div>
                        </section>

                        {/* Text-to-Speech Model Selection */}
                        <section className="model-section">
                            <h3>Text-to-Speech Model Selection</h3>
                            <ModelTable 
                                models={ttsModelData} 
                                selectedModel={selectedTtsModel} 
                                onSelectModel={(model) => handleSelectModel('tts', model)}
                                columns={['Select', 'Model', 'Provider', 'Host']}
                            />
                            <div className="custom-instructions">
                                <label>Custom Instructions for Text-to-Speech Model</label>
                                <textarea 
                                    placeholder="Type custom instructions here..." 
                                    value={ttsModelInstructions}
                                    onChange={(e) => setTtsModelInstructions(e.target.value)}
                                ></textarea>
                            </div>
                        </section>

                        {/* Speech-to-Text Model Selection */}
                        <section className="model-section">
                            <h3>Speech-to-Text Model Selection</h3>
                            <ModelTable 
                                models={sttModelData} 
                                selectedModel={selectedSttModel} 
                                onSelectModel={(model) => handleSelectModel('stt', model)}
                                columns={['Select', 'Model', 'Provider', 'Host']}
                            />
                            <div className="custom-instructions">
                                <label>Custom Instructions for Speech-to-Text Model</label>
                                <textarea 
                                    placeholder="Type custom instructions here..." 
                                    value={sttModelInstructions}
                                    onChange={(e) => setSttModelInstructions(e.target.value)}
                                ></textarea>
                            </div>
                        </section>
                        

                        {/* Prompting Section */}
                        <section className="model-section">
                            <h3>Prompting</h3>
                            <div>
                                <label>Custom Instructions</label>
                                <textarea placeholder="Type custom instructions here..." value={customInstructions} onChange={(e) => setCustomInstructions(e.target.value)}></textarea>
                            </div>
                            <div>
                                <label>Extra Information</label>
                                <textarea placeholder="Additional details..." value={extraInformation} onChange={(e) => setExtraInformation(e.target.value)}></textarea>
                            </div>
                            <div>
                                <label>Things to Avoid Saying</label>
                                <textarea placeholder="Enter topics or phrases to avoid..." value={avoidSaying} onChange={(e) => setAvoidSaying(e.target.value)}></textarea>
                            </div>
                            <div>
                                <label>Persona</label>
                                <input type="text" placeholder="Define the persona of the chat model" value={modelPersona} onChange={(e) => setModelPersona(e.target.value)} />
                            </div>
                        </section>
                    </div>
                    <div className="modal-footer">
                        <button onClick={handleSubmit} className="submit-button">Create Chat Model</button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

const ModelTable = ({ models, selectedModel, onSelectModel, columns }) => {
    return (
        <table className="model-table">
            <thead>
                <tr>
                    {columns.map((column, idx) => (
                        <th key={idx}>{column}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {models.map((model, index) => (
                    <tr 
                        key={index} 
                        onClick={() => onSelectModel(model)}
                        style={{ cursor: 'pointer' }} // Optional for visual feedback
                    >
                        {columns.includes('Select') && (
                            <td>
                                <Icon 
                                    path={selectedModel === model ? mdiRadioboxMarked : mdiRadioboxBlank} 
                                    size={1} 
                                />
                            </td>
                        )}
                        {columns.includes('Model') && <td>{model.model}</td>}
                        {columns.includes('Max Tokens') && <td>{model.maxTokens}</td>}
                        {columns.includes('Description') && <td>{model.description}</td>}
                        {columns.includes('Pros') && <td>{model.pros}</td>}
                        {columns.includes('Cons') && <td>{model.cons}</td>}
                        {columns.includes('Vector Dimensions') && <td>{model.vectorDimensions || 'N/A'}</td>}
                        {columns.includes('Provider') && <td>{model.provider}</td>}
                        {columns.includes('Host') && <td>{model.host}</td>}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ChatModel;
