import React, { useState, useEffect } from 'react';
import '../styles/ApiUiGen.css';
import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import * as queries from '../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify';

const OpenAPIForm = ({ jsonSchema, onSaveIntegrationsState  }) => {
  const [selectedEndpoint, setSelectedEndpoint] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');
  const [methodDetails, setMethodDetails] = useState({});
  const [parameterValues, setParameterValues] = useState({});
  const [authCode, setAuthCode] = useState('');
  // Extract global API information
  const apiInfo = jsonSchema.api_info || {};
  // Use the correct reference for endpoints
  const endpointsInfo = jsonSchema.endpoints || {};

  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setSelectedMethod('');
    if (selectedEndpoint) {
      const initialMethodDetails = endpointsInfo[selectedEndpoint][0] || {};
      setMethodDetails(initialMethodDetails);
      resetParameterValues(initialMethodDetails);
    } else {
      setMethodDetails({});
      setParameterValues({});
    }
  }, [selectedEndpoint, endpointsInfo]);

  const handleEndpointChange = (e) => {
    setSelectedEndpoint(e.target.value);
  };

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
    const methodInfo = endpointsInfo[selectedEndpoint].find(method => method.method === e.target.value) || {};
    setMethodDetails(methodInfo);
    resetParameterValues(methodInfo);
  };

  const resetParameterValues = (methodInfo) => {
    const initialValues = {};
    methodInfo.parameters?.forEach(param => {
      initialValues[param.name] = '';
    });
    setParameterValues(initialValues);
  };


  const removeFullnameTag = (htmlString) => {
    return htmlString.replace(/<fullname>.*?<\/fullname>/g, '');
  };

  const createMarkup = (htmlString) => {
    const cleanedString = removeFullnameTag(htmlString);
    return { __html: cleanedString };
  };

  // Function to determine the input type based on the parameter schema
  const getInputType = (param) => {
    switch (param.type) {
      case 'string':
        return param.enum ? 'enum' : 'text';
      case 'number':
      case 'integer':
        return 'number';
      case 'boolean':
        return 'checkbox';
      default:
        return 'text';
    }
  };

  // Render the correct input field based on the parameter type
  const renderInputField = (param) => {
    const inputType = getInputType(param);
    const value = parameterValues[param.name] || '';

    switch (inputType) {
      case 'enum':
        return (
          <div className='api-form-dropdown'>
            <select name={param.name} value={value} onChange={handleParameterChange}>
              {param.enum.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
        );
      case 'checkbox':
        return (
          <input 
            type="checkbox" 
            name={param.name} 
            checked={value} 
            onChange={(e) => handleParameterChange(e, 'checkbox')}
          />
        );
      default:
        return (
          <input 
            type={inputType} 
            name={param.name} 
            value={value} 
            onChange={handleParameterChange}
          />
        );
    }
  };

  // Update handleParameterChange to support different input types
  const handleParameterChange = (e, inputType = 'text') => {
    const { name, value, checked } = e.target;
    setParameterValues({
      ...parameterValues, 
      [name]: inputType === 'checkbox' ? checked : value
    });
  };

  const isCommonEmailDomain = (email) => {
    const commonDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com'];
    const domain = email.split('@')[1];
    return commonDomains.includes(domain.toLowerCase());
  };
  const [authDetails, setAuthDetails] = useState({});
  const securitySchemes = jsonSchema.security_schemes || {};

  const handleAuthChange = (e) => {
    const { name, value } = e.target;
    setAuthDetails({ ...authDetails, [name]: value });
  };

  const renderAuthField = (schemeName, schemeDetails) => {
    switch (schemeDetails.type) {
      case 'oauth2':
        return Object.entries(schemeDetails.flows).map(([flowName, flowDetails]) => {
          // Check if flowDetails is defined
          if (flowDetails) {
            return renderOAuthFlowButton(schemeName, flowName, flowDetails);
          }
          return null;
        });
      case 'apiKey':
        return (
          <div key={schemeName} className="auth-input-container">
            <h4>{schemeName}</h4>
            <p>{schemeDetails.description}</p>
            <input 
              type="text" 
              name={schemeName}
              value={authDetails[schemeName] || ''} 
              onChange={handleAuthChange}
              placeholder={`Enter ${schemeName}`}
            />
          </div>
        );
      // Add more cases for other authentication types
      default:
        return null;
    }
  };
  const initiateOAuthFlow = (flowDetails, isGoogleOAuth = false) => {
    if (onSaveIntegrationsState) {
        onSaveIntegrationsState(); // Call the function passed as a prop
    }
    // Save current state before redirecting
    const currentState = {
      selectedEndpoint,
      selectedMethod,
      methodDetails,
      parameterValues,
      authDetails
    };
    sessionStorage.setItem('openAPIFormState', JSON.stringify(currentState));
  
    let clientId = isGoogleOAuth ? process.env.REACT_APP_GOOGLE_CLIENT_ID : flowDetails.clientId;
  
    let authUrl = flowDetails.authorizationUrl;
    let queryParams = new URLSearchParams();
   
    queryParams.append('response_type', 'code'); // or 'token' for implicit flow
    queryParams.append('client_id', clientId); // Use the client ID from the environment variable if it's a Google OAuth flow
    queryParams.append('redirect_uri', 'http://localhost:3000/integrations'); // Replace with your actual redirect URI
  
    let scopeString = '';
    if (Array.isArray(flowDetails.scopes)) {
      scopeString = flowDetails.scopes.join(' ');
    } else if (typeof flowDetails.scopes === 'object') {
      scopeString = Object.keys(flowDetails.scopes).join(' ');
    }
   
    queryParams.append('scope', scopeString);
   
    window.location.href = `${authUrl}?${queryParams.toString()}`;
  };

  useEffect(() => {
    // Extract OAuth information from URL
    const urlParams = new URLSearchParams(window.location.search);
    const oauthToken = urlParams.get('token'); // or 'code', depending on your OAuth flow
  
    if (oauthToken) {
      setIsAuthenticated(true);
      setAuthDetails(prevDetails => ({
        ...prevDetails,
        oauthToken: oauthToken
      }));
      // Optionally, store the token in localStorage for persistent authentication
      localStorage.setItem('oauthToken', oauthToken);
  
      // Remove OAuth parameters from URL
      window.history.replaceState(null, null, window.location.pathname);
    }
    // Extract OAuth information from URL
    const code = urlParams.get('code');

    if (code) {
      setIsAuthenticated(true);
      setAuthCode(code)
      setAuthDetails(prevDetails => ({
        ...prevDetails,
        code: code
      })); // Store the auth code in state

      // Remove OAuth parameters from URL
      urlParams.delete('code');
      window.history.replaceState(null, null, window.location.pathname);
    }
    const savedState = sessionStorage.getItem('openAPIFormState');
    if (savedState) {
      console.log("saved state: ", savedState)
      const { selectedEndpoint, selectedMethod, methodDetails, parameterValues, authDetails } = JSON.parse(savedState);
      setSelectedEndpoint(selectedEndpoint);
      setSelectedMethod(selectedMethod);
      setMethodDetails(methodDetails);
      setParameterValues(parameterValues);
      setAuthDetails(authDetails);
      sessionStorage.removeItem('openAPIFormState'); // Clear the saved state
    } else {
      setSelectedMethod('');
      if (selectedEndpoint) {
        const initialMethodDetails = endpointsInfo[selectedEndpoint][0] || {};
        setMethodDetails(initialMethodDetails);
        resetParameterValues(initialMethodDetails);
      } else {
        setMethodDetails({});
        setParameterValues({});
      }
    }
  }, [selectedEndpoint, endpointsInfo, window.location.search]);
  

  const renderAuthFields = () => {
    return Object.entries(securitySchemes).map(([schemeName, schemeDetails]) => {
      if (schemeDetails.type === 'oauth2' && schemeDetails.flows) {
        return Object.entries(schemeDetails.flows).map(([flowType, flowDetails]) => {
          if (!flowDetails) {
            console.error(`Flow details are undefined for ${flowType} in ${schemeName}`);
            return null;
          }
  
          switch (flowType) {
            case 'implicit':
            case 'authorizationCode':
              // Handle Implicit and Authorization Code flows
              // Example call for Google OAuth flow
              return renderOAuthFlowButton('GoogleOAuth', flowDetails, true);

            case 'password':
              // Handle Password flow
              return renderPasswordFlowInputs(schemeName, flowDetails);
            case 'clientCredentials':
              // Handle Client Credentials flow
              return renderClientCredentialsFlowInputs(schemeName, flowDetails);
            default:
              return null;
          }
        });
      }
      // Handle other authentication types as needed
    });
  };
  
  const renderPasswordFlowInputs = (schemeName, flowDetails) => {
    return (
      <div key={`${schemeName}-password`} className="auth-input-container">
        <h4>{schemeName} - Password Flow</h4>
        <input
          type="text"
          name={`${schemeName}-username`}
          placeholder="Username"
          value={authDetails[`${schemeName}-username`] || ''}
          onChange={handleAuthChange}
        />
        <input
          type="password"
          name={`${schemeName}-password`}
          placeholder="Password"
          value={authDetails[`${schemeName}-password`] || ''}
          onChange={handleAuthChange}
        />
      </div>
    );
  };
  
  const renderClientCredentialsFlowInputs = (schemeName, flowDetails) => {
    return (
      <div key={`${schemeName}-clientCredentials`} className="auth-input-container">
        <h4>{schemeName} - Client Credentials Flow</h4>
        <input
          type="text"
          name={`${schemeName}-clientId`}
          placeholder="Client ID"
          value={authDetails[`${schemeName}-clientId`] || ''}
          onChange={handleAuthChange}
        />
        <input
          type="password"
          name={`${schemeName}-clientSecret`}
          placeholder="Client Secret"
          value={authDetails[`${schemeName}-clientSecret`] || ''}
          onChange={handleAuthChange}
        />
      </div>
    );
  };
  
  
  const renderOAuthFlowButton = (schemeName, flowDetails) => {
    // Check if it's a Google OAuth flow
    const isGoogleOAuth = flowDetails.authorizationUrl.includes("google.com/o/oauth2") || 
                          (flowDetails.tokenUrl && flowDetails.tokenUrl.includes("google.com/o/oauth2"));
  
    return (
      <div key={schemeName} className="auth-input-container">
        <h4>{schemeName}</h4>
        <p>{flowDetails.description}</p>
        <button type="button" onClick={() => initiateOAuthFlow(flowDetails, isGoogleOAuth)}>
          Authenticate with {schemeName}
        </button>
      </div>
    );
  };
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const makeApiCall = async () => {
    try {
      setApiResponse(null);
      setApiError(null);
  
      // Constructing the API URL
      const baseUrl = apiInfo.servers?.[0]?.url || '';
      let endpoint = selectedEndpoint;
  
      // Replacing URL parameters with values
      for (const [paramName, paramValue] of Object.entries(parameterValues)) {
        if (paramValue) {
          endpoint = endpoint.replace(`{${paramName}}`, encodeURIComponent(paramValue));
        }
      }
  
      let url = `${baseUrl.replace(/\/+$/, '')}/${endpoint.replace(/^\/+/, '')}`;
  
      // Prepending 'https://' if not already present
      if (!url.startsWith('https://')) {
        url = 'https://' + url;
      }
  
      // Filtering out blank parameters
      const filteredParams = Object.fromEntries(
        Object.entries(parameterValues).filter(([key, value]) => value)
      );
  
      // Preparing auth credentials
      const authCredentials = {};
      if (authDetails.apiKey) {
        authCredentials['apiKey'] = authDetails.apiKey;
      }
      if (authDetails.oauthToken) {
        // Use the OAuth token for authentication
        authCredentials["oauth2_client_credentials"] = `Bearer ${authDetails.oauthToken}`;
      }
      if (authDetails.code) {
        // Use the OAuth token for authentication
        authCredentials['oauth2_client_credentials'] = `${authDetails.code}`;
      }
  
      // Preparing GraphQL variables
      const variables = {
        input: {
          api_var: JSON.stringify({
            api_endpoint: url,
            params: filteredParams,
            headers: {}, // Add headers if needed
            auth_credentials: authCredentials,
          })
        }
      };
  
      // Making the GraphQL API call
      const graphqlResponse = await API.graphql(graphqlOperation(queries.CallExternalApi, variables));
  
      if (graphqlResponse.errors) {
        throw new Error(`GraphQL error! ${graphqlResponse.errors}`);
      }
  
      // Parsing and formatting the API response
      let apiResponseData = JSON.parse(graphqlResponse.data.CallExternalApi.response);
      setApiResponse(JSON.stringify(apiResponseData.body, null, 2));
    } catch (error) {
      setApiError(error.message);
    }
  };
  
  return (
    <form>
      <div className="api-global-info">
        <h2>{apiInfo.info?.title || 'API Documentation'}</h2>
        <div dangerouslySetInnerHTML={createMarkup(apiInfo.info?.description)} />
        {apiInfo.info?.contact?.email && !isCommonEmailDomain(apiInfo.info.contact.email) && (
          <div className="api-contact-info">
            <strong>Contact:</strong> {apiInfo.info.contact.name} (<a href={`mailto:${apiInfo.info.contact.email}`}>{apiInfo.info.contact.email}</a>)
          </div>
        )}
        {Object.keys(securitySchemes).length > 0 && (
          <div className="api-authentication">
            <h3>Authentication:</h3>
            {isAuthenticated ? (
              <div style={{ color: 'green', fontWeight: 'bold' }}>
                Authenticated <Icon path={mdiCheck} size={1} />
              </div>
            ) : (
              renderAuthFields()
            )}
          </div>
        )}
        {apiInfo.info?.termsOfService && (
          <div className="api-terms">
            <a href={apiInfo.info.termsOfService} target="_blank" rel="noopener noreferrer">Terms of Service</a>
          </div>
        )}
        {apiInfo.info?.license && (
          <div className="api-license">
            Licensed under <a href={apiInfo.info.license.url} target="_blank" rel="noopener noreferrer">{apiInfo.info.license.name}</a>
          </div>
        )}
        {apiInfo.info?.['x-apiClientRegistration'] && (
          <div className="api-client-registration">
            <a href={apiInfo.info['x-apiClientRegistration'].url} target="_blank" rel="noopener noreferrer">Client Registration</a>
          </div>
        )}
      </div>
      <div className="api-form-dropdown">
        <select value={selectedEndpoint} onChange={handleEndpointChange}>
          <option value="">Select Endpoint</option>
          {Object.keys(endpointsInfo).map((endpoint) => (
            <option key={endpoint} value={endpoint}>{endpoint}</option>
          ))}
        </select>
      </div>

      {selectedEndpoint && (
        <div className="api-form-dropdown">
          <select value={selectedMethod} onChange={handleMethodChange}>
            <option value="">Select Method</option>
            {endpointsInfo[selectedEndpoint].map((method, index) => (
              <option key={index} value={method.method}>{method.method}</option>
            ))}
          </select>
        </div>
      )}

      {selectedMethod && (
        <div className="api-method-details">
          <h3>Method Details:</h3>
          <p><strong>Description:</strong></p>
          <div dangerouslySetInnerHTML={createMarkup(methodDetails.description)} />

          <strong>Parameters:</strong>
          {methodDetails.parameters && methodDetails.parameters.map((param, index) => (
            <div key={index} className="parameter-input-container">
              <label>{param.name} ({param.type}):</label>
              {renderInputField(param)}
            </div>
          ))}
        </div>
      )}
      <button type="button" onClick={makeApiCall}>Test API</button>

      {apiResponse && (
        <div className="api-response">
          <h4>API Response:</h4>
          <pre>{apiResponse}</pre>
        </div>
      )}

      {apiError && (
        <div className="api-error">
          <h4>Error:</h4>
          <p>{apiError}</p>
        </div>
      )}
    </form>
  );
};

export default OpenAPIForm;
